import React from 'react'
import { Button, Col, FormGroup, Row, Spinner } from 'reactstrap';
import DatePicker from "react-datepicker";
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { AddInstructorScheduleThunk, ResetAdddInstructorScheduleData } from '../../../redux/InstructorSlice/AddInstructorScheduleSlice';
import { GetInstructorScheduleThunk } from '../../../redux/InstructorSlice/GetInstructorScheduleSlice';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { useState } from 'react';
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Icon } from '../../../components/Component';

const AddInstructorSlot = ({modal, setModal, selectedLocations, oldLocations, setOldLocations, setSelectedLocations, GetInstructorScheduleLoading, selectedDay}) => {
  const dispatch = useDispatch();

  const { instructorId } = useParams();
  const {
    loading: AddInstructorScheduleLoading,
    success: AddInstructorScheduleSuccess,
    error: AddInstructorScheduleError,
    errorMessage: AddInstructorScheduleErrorMessage,
  } = useSelector((state) => state.instructors.addInstrcutorSchedule);

  const [scheduleTime, setScheduleTime] = useState({
    startTime: moment().startOf("hour").toDate(),
    endTime: moment().add(1, "hour").startOf("hour").toDate(),
  });

  
  const handleSubmitAddAvailabitlity = (e) => {
    const startAddOnHours = moment(scheduleTime?.startTime).toDate().getHours();
    const startAddOnMinutes = moment(scheduleTime?.startTime).toDate().getMinutes();
    const endAddOnHours = moment(scheduleTime?.endTime).toDate().getHours();
    const endAddOnMinutes = moment(scheduleTime?.endTime).toDate().getMinutes();

    dispatch(
      AddInstructorScheduleThunk({
        instructorId,
        newStartTime: moment(scheduleTime?.startTime)
          .startOf("year").year(2022)
          .add("hours", startAddOnHours)
          .add("minutes", startAddOnMinutes)
          .utcOffset(0, true)
          .toISOString(),

        newEndTime: moment(scheduleTime?.endTime)
          .startOf("year").year(2022)
          .add("hours", endAddOnHours)
          .add("minutes", endAddOnMinutes)
          .utcOffset(0, true)
          .toISOString(),
        locations: selectedLocations,
        day: selectedDay,
        weekly: true,
      })
    );
  };

  if (AddInstructorScheduleSuccess) {
    toast.success("Instructor Time Slot has been added successfully");
    dispatch(ResetAdddInstructorScheduleData());
    dispatch(GetInstructorScheduleThunk({ instructorId }));
    setModal({...modal, addSlot: false, showWeeklySlots: true });
  }
  
  if (AddInstructorScheduleError) {
    toast.error(AddInstructorScheduleErrorMessage);
    dispatch(ResetAdddInstructorScheduleData());
    dispatch(GetInstructorScheduleThunk({ instructorId }));
    setModal({ ...modal, addSlot: false, showWeeklySlots: true });
  }

  const { handleSubmit } = useForm();
  return (
    <React.Fragment>
      <BlockHead size="sm">
        <BlockBetween>
            <BlockHeadContent>
            <BlockTitle page>Add Schedule</BlockTitle>
            </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      {GetInstructorScheduleLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <Spinner />
        </div>
      ) : (
        <form className="form-validate is-alter" onSubmit={handleSubmit(handleSubmitAddAvailabitlity)}>
          <div className=' mb-5'>
            <Button onClick={()=> setModal({showDaySlots: true, addSlot: false })}> 
                <Icon name="back-arrow-fill"/>
                {" "} Back
            </Button>
          </div>

          <Row className="gx-4 gy-3">
            <Col sm="6">
              <FormGroup>
                <label className="form-label">Start Time</label>
                <Row className="gx-2" style={{ zIndex: "2" }}>
                  <div className="w-100" style={{ zIndex: "2" }}>
                    <div className="form-control-wrap has-timepicker" style={{ zIndex: "2" }}>
                      <DatePicker
                        style={{ zIndex: "2" }}
                        selected={scheduleTime.startTime}
                        onChange={(date) => setScheduleTime({ ...scheduleTime, startTime: date })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control date-picker"
                      />
                    </div>
                  </div>
                </Row>
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <label className="form-label">End Time</label>
                <Row className="gx-2">
                  <div className="w-100">
                    <div className="form-control-wrap has-timepicker" style={{ opacity: 3 }}>
                      <DatePicker
                        selected={scheduleTime.endTime}
                        onChange={(date) => setScheduleTime({ ...scheduleTime, endTime: date })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control date-picker"
                      />
                    </div>
                  </div>
                </Row>
              </FormGroup>
            </Col>
            {/* <Col size="12">
              <FormGroup>
                <label className="form-label">Locations</label>
                <Row className="gx-2">
                  <div className="w-100">
                    <div className="form-control-wrap">
                      <ul className="link-tidy ml-0">
                        {oldLocations?.map((location) => {
                          return (
                            <li>
                              <div className="custom-control custom-control-sm custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  checked={location.isChecked}
                                  name={location?.name}
                                  onClick={(e) => {
                                    const difLocation = oldLocations.map((oldLoc)=>{
                                      const data = oldLoc?._id === e.target.value ? {_id:oldLoc?._id,name: oldLoc?.name, isChecked: e.target.checked }: oldLoc
                                      return data;
                                    });
                                    setOldLocations(difLocation);
                                    e.target.checked
                                    ? setSelectedLocations( selectedLoc => [...selectedLoc, location?._id])
                                    : setSelectedLocations(selectedLoc => {
                                      const updatedSelectedLoc = [...selectedLoc, location._id];
                                      const uniqueSelectedLoc = updatedSelectedLoc.filter((value, index, self) => {
                                        return self.indexOf(value) === index;
                                      });
                                      return uniqueSelectedLoc;
                                    })
                                    }}
                                  value={location?.name}
                                  id={location?.name}
                                />
                                <label className="custom-control-label" htmlFor={location?.name}>
                                  {location?.name}
                                </label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </Row>
              </FormGroup>
            </Col> */}
            <Col size="12">
              <ul className="d-flex justify-content-start gx-4 mt-1">
                <li>
                  <Button disabled={AddInstructorScheduleLoading} type="submit" color="primary">
                    {AddInstructorScheduleLoading ? <Spinner size="sm" /> : "Add Availibility"}
                  </Button>
                </li>
                <li>
                  <Button
                    color="#ffffff"
                    className="btn-dim"
                    onClick={() => setModal({ addSlot: false })}
                  >
                    Cancel
                  </Button>
                </li>
              </ul>
            </Col>
          </Row>
        </form>
      )}

    </React.Fragment>
  )
}

export default AddInstructorSlot;