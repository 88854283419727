import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// add student
const GetAllInvoicesThunk = createAsyncThunk(
  "AllInvoices/GetAllInvoicesThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(ApiEndpoints.Invoices.GetAllInvoices, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });

      return response.data;
    } catch (error) {
      console.log("error", error);
      const errorMessage = error?.response?.data?.message || "Something went wrong";
      return rejectWithValue(errorMessage);
    }
  }
);

const GetAllInvoicesSlice = createSlice({
  name: "AllInvoices",
  initialState: {
    invoicesData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetInvoicesData: (state, action) => {
      state.invoicesData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [GetAllInvoicesThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GetAllInvoicesThunk.fulfilled]: (state, action) => {
      state.invoicesData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [GetAllInvoicesThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.error = true;
      state.loading = false;
    },
  },
});

export default GetAllInvoicesSlice.reducer;

export const { ResetInvoicesData } = GetAllInvoicesSlice.actions;

export { GetAllInvoicesThunk };
