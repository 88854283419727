import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const UpdateStudentNotificationThunk = createAsyncThunk(
    "UpdateStudentNotification/UpdateStudentNotificationThunk",
    async ({ field, value }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiEndpoints.Configuration.UpdateStudentNotification}`,
                { field, value },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                }
            );
            return response.data;
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error.response.data);
        }
    }
);

const UpdateStudentNotificationSlice = createSlice({
    name: "UpdateStudentNotification",
    initialState: {
        UpdateStudentNotificationData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetUpdateStudentNotificationData: (state, action) => {
            state.UpdateStudentNotificationData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },

    extraReducers: {
        [UpdateStudentNotificationThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [UpdateStudentNotificationThunk.fulfilled]: (state, action) => {
            state.UpdateStudentNotificationData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [UpdateStudentNotificationThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
        },
    },
});

export default UpdateStudentNotificationSlice.reducer;

export const { ResetUpdateStudentNotificationData } = UpdateStudentNotificationSlice.actions;

export { UpdateStudentNotificationThunk };
