import React from 'react';
import { FormGroup, Modal, ModalBody, Spinner, Col, Button, Card, CardBody, CardText, CardTitle, Progress } from "reactstrap";
import { Icon, RSelect } from "../../../../components/Component";
import { Formik, Form, Field } from "formik";
import { AddStudentInitialValues } from "../../../../utils/FormValues";
import { AddStudentValidationSchema } from "../../../../utils/FormValidationSchema";

const StudentLessonInCarReport = ({modal, setModal}) => {


    return (
        <React.Fragment>
                <Modal isOpen={modal.viewInCarReport} toggle={() => setModal({ viewInCarReport: false })} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <div onClick={(ev) => { ev.preventDefault();setModal({ viewInCarReport: false }) }}
            className="close cursor_pointer"
        >
            <Icon name="cross-sm"></Icon>
            
        </div>

        <div className="p-2">
          <h5 className="title">Add Student</h5>
          {/* {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
              <Spinner />
            </div>
          ) : ( */}
            <div className="mt-4">
              <Formik
                initialValues={AddStudentInitialValues}
                validationSchema={AddStudentValidationSchema}
                enableReinitialize={true}
                // onSubmit={onFormSubmit}
              >
                {({ errors, touched }) => (
                  <Form className="row gy-4 mt-4">

                    <Col md="12">
                        <div className='d-flex justify-content-between'>
                            <div>
                                <Icon name="calender-date"/>
                                <span>Lesson Date: 01/09/2022</span>
                            </div>
                            <div style={{backgroundColor: "#00AD3010"}}>
                                Completed
                            </div>
                        </div>
                    </Col>

                    <Col md="12"><h6>Topics</h6></Col>
                    <Col md="12">
                    <Card className="card-bordered w-100 mb-1">
                            <CardBody className="card-inner">
                                <CardText tag="p" style={{ color: "rgb(92 106 124)", marginBottom: "0.2rem" }}>
                                    <h6> Instrument Devices, Circle Check</h6>
                                    
                                    <div className="d-flex justify-content-start">
                                        <span>Grade</span>
                                        <Progress color="info" value="75" />

                                        {/* <span style={{color: "#37a2d0"}} >${invoiceData?.unitPrice *invoiceData?.quantity }</span> */}
                                    </div>
                                    <Progress className='progress-lg' color="success" value="100" > 3 </Progress>
                                    {/* <div className="d-flex justify-content-between mt-3">
                                    <div>
                                    <span>Description</span>
                                    <br/>
                                    <span style={{color: 'black'}}>{invoiceData?.type}</span>
                                    </div>
                                    <div>
                                    <span >Qty</span>
                                    <br/>
                                    <span style={{color: 'black'}}>{invoiceData?.quantity}</span>
                                    </div>
                                    <div>
                                    <span >Unit Price</span>
                                    <br/>
                                    <span style={{color: 'black'}}>{invoiceData?.unitPrice}</span>
                                    </div>
                                </div> */}
                                </CardText>
                                {/* <Progress color="info" value="75" /> */}

                                <CardTitle tag="h6"></CardTitle>
                            </CardBody>
                        </Card>

                    </Col>

                    </Form>
                )}
                </Formik>
            </div>
          {/* )} */}
        </div>
      </ModalBody>
    </Modal>

        </React.Fragment>
    )
};

export default StudentLessonInCarReport;