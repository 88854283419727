import React, { useState } from "react";
import { Block, BlockContent, BlockHead, BlockTitle, Button, PreviewCard } from "../components/Component";
import { Spinner, FormGroup } from "reactstrap";
import PageContainer from "../layout/page-container/PageContainer";
import Head from "../layout/head/Head";
import { Field, Form, Formik } from "formik";
import { ChangePasswordInitialValues } from "../utils/FormValues";
import { ChangePasswordValidationSchema } from "../utils/FormValidationSchema";

const ChangeAdminPassword = () => {
  const [loading, setLoading] = useState(false);

  const handleChangeAdminPassword = () => {
    setLoading(true);
  };
  return (
    <React.Fragment>
      <div className="mt-5">
        <Head title="Change Password" />
        <PageContainer>
          <Block className="nk-block-middle nk-auth-body  wide-xs">
            <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
              <BlockHead>
                <BlockContent>
                  <BlockTitle tag="h4">Change Password</BlockTitle>
                </BlockContent>
              </BlockHead>
              <Formik
                initialValues={ChangePasswordInitialValues}
                validationSchema={ChangePasswordValidationSchema}
                enableReinitialize={true}
              >
                {({ errors, touched }) => (
                  <Form className="is-alter">
                    <FormGroup>
                      <label className="form-label" htmlFor="password">
                        New Password
                      </label>
                      <div className="form-control-wrap">
                        <Field
                          type="password"
                          id="password"
                          name="password"
                          placeholder="Enter New Password"
                          className="form-control-lg form-control"
                        />
                        {errors.password && <p className="invalid">This field is required</p>}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="confirmPassword">
                          Confirm Password
                        </label>
                      </div>
                      <div className="form-control-wrap">
                        <input
                          type="password"
                          bssize="lg"
                          id="confirmPassword"
                          name="password"
                          className="form-control-lg form-control"
                          placeholder="Confirm Password"
                        />
                        {errors.email && <p className="invalid">This field is required</p>}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Button type="submit" color="primary" size="lg" className="btn-block">
                        {loading ? <Spinner size="sm" color="light" /> : "Change Password"}
                      </Button>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </PreviewCard>
          </Block>
        </PageContainer>
      </div>
    </React.Fragment>
  );
};

export default ChangeAdminPassword;
