import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import StudentPersonalInformation from "./StudentPersonalInformation";
import StudentInstructorDetail from "./StudentInstructorDetail";
import StudentsLesson from "./StudentsLesson";
import { Link, useParams } from "react-router-dom";
import { Icon, UserAvatar } from "../../components/Component";
import { findUpper } from "../../utils/Utils";
import { Card } from "reactstrap";
import StudentReviews from "./StudentsReview";
import StudentRoadTest from "./StudentRoadTest";
import StudentSchedule from "./StudentSchedule";
import StudentLesson from "./StudentLesson";
import InCarsheet from "./In-CarSheet";
import ViewInCarSheet from "./In-CarSheet/ViewInCarSheet";
import AddInCarSheet from "./In-CarSheet/AddInCarSheet";
import EditInCarSheet from "./In-CarSheet/EditInCarSheet";
import SimulatedTestInCarSheet from "./In-CarSheet/SimulatedTestInCarSheet";
import StudentNotes from "./StudentNotes";
import Refunds from "./Refunds";

const Students = () => {
  const { studentId } = useParams();
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [profileName, setProfileName] = useState("");
  const [profileEmail, setProfileEmail] = useState("");
  const [openDetails, setOpenDetails] = useState("studentProfile");

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="user-card">
                    <UserAvatar text={findUpper(profileName)} theme="primary" />
                    <div className="user-info">
                      <span className="lead-text">{profileName}</span>
                      <span className="sub-text">{profileEmail}</span>
                    </div>
                  </div>
                </div>
                <div className="card-inner p-0">
                  <ul className="link-list-menu">
                  <li onClick={() => updateSm(false)}>
                      <Link
                        onClick={() => setOpenDetails("studentProfile")}
                        className={openDetails === `studentProfile` ? "active" : ""}
                      >
                        <Icon name="user-check"></Icon>
                        <span>Personal Information</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link
                        onClick={() => setOpenDetails("inCarSheet")}
                        className={openDetails === `inCarSheet` ? "active" : ""}
                      >
                        <Icon name="user-check"></Icon>
                        <span>In-Car Sheet</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link
                        onClick={() => setOpenDetails("studentInstructor")}
                        className={openDetails === `studentInstructor` ? "active" : ""}
                      >
                        <Icon name="users" /> <span>Student Instructor</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link
                        onClick={() => setOpenDetails("studentLessons")}
                        className={openDetails === `studentLessons` ? "active" : ""}
                      >
                        <Icon name="list-index" /> <span>Student's Lessons</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link
                        onClick={() => setOpenDetails("studentRefunds")}
                        className={openDetails === `studentRefunds` ? "active" : ""}
                      >
                        <Icon name="list-index" /> <span>Refunds</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link
                        onClick={() => setOpenDetails("studentRoadTest")}
                        className={openDetails === `studentRoadTest` ? "active" : ""}
                      >
                        <Icon name="list" /> <span>Road Test</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link
                        onClick={() => setOpenDetails("studentReviews")}
                        className={openDetails === `studentReviews` ? "active" : ""}
                      >
                        <Icon name="comments" /> <span>Reviews</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link
                        onClick={() => setOpenDetails("studentNotes")}
                        className={openDetails === `studentNotes` ? "active" : ""}
                      >
                        <Icon name="comments" /> <span>Notes</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}

              {openDetails === "studentProfile" && (
                <StudentPersonalInformation
                  updateSm={updateSm}
                  sm={sm}
                  setProfileName={setProfileName}
                  setProfileEmail={setProfileEmail}
                  studentId={studentId}
                />
              )}
              {openDetails === "studentInstructor" && (
                <StudentInstructorDetail
                  updateSm={updateSm}
                  sm={sm}
                  setProfileName={setProfileName}
                  studentId={studentId}
                />
              )}
              {openDetails === "studentLessons" && (
                <StudentLesson updateSm={updateSm} sm={sm} setProfileName={setProfileName} studentId={studentId} />
              )}
              {openDetails === "studentRefunds" && (
                <Refunds updateSm={updateSm} sm={sm} setProfileName={setProfileName} studentId={studentId} />
              )}
              {openDetails === "studentRoadTest" && (
                <StudentRoadTest updateSm={updateSm} sm={sm} setProfileName={setProfileName} studentId={studentId} />
              )}
              {openDetails === "studentReviews" && (
                <StudentReviews updateSm={updateSm} sm={sm} setProfileName={setProfileName} studentId={studentId} />
              )}
              {openDetails === "studentSchedule" && (
                <StudentSchedule updateSm={updateSm} sm={sm} setProfileName={setProfileName} studentId={studentId} />
              )}
              {openDetails === "inCarSheet" && (
                <InCarsheet updateSm={updateSm} sm={sm} setProfileName={setProfileName} studentId={studentId} />
              )}
              {openDetails === "studentNotes" && (
                <StudentNotes updateSm={updateSm} sm={sm} setProfileName={setProfileName} studentId={studentId} />
              )}
              {openDetails === "test" && (
                <AddInCarSheet updateSm={updateSm} sm={sm} setProfileName={setProfileName} studentId={studentId} />
              )}
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default Students;
