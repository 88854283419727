import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const ChangeLessonExpiryDateThunk = createAsyncThunk(
    "ChangeLessonExpiryDate/ChangeLessonExpiryDateThunk",
    async ({ lessonId, expiryDate }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiEndpoints.LesssonsEndPoint.ChangeLessonExpiryDate}?lessonId=${lessonId}`,
                { expiryDate },
                {
                headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                }
            );
            return response.data;
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error.response.data);
        }
    }
);

const ChangeLessonExpiryDateSlice = createSlice({
    name: "ChangeLessonExpiryDate",
    initialState: {
        ChangeLessonExpiryData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetChangeLessonExpiryData: (state, action) => {
        state.ChangeLessonExpiryData = [];
        state.success = false;
        state.error = false;
        state.loading = false;
        state.errorMessage = "";
        },
    },
    extraReducers: {
        [ChangeLessonExpiryDateThunk.pending]: (state, action) => {
        state.loading = true;
        state.success = false;
        state.error = false;
        },
        [ChangeLessonExpiryDateThunk.fulfilled]: (state, action) => {
        state.ChangeLessonExpiryData = action.payload;
        state.success = true;
        state.loading = false;
        },
        [ChangeLessonExpiryDateThunk.rejected]: (state, action) => {
        state.errorMessage = action.payload.message;
        state.error = true;
        state.loading = false;
        },
    },
});

export default ChangeLessonExpiryDateSlice.reducer;

export const { ResetChangeLessonExpiryData } = ChangeLessonExpiryDateSlice.actions;

export { ChangeLessonExpiryDateThunk };
