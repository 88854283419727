import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// add Geo Fencing
const AddGeoFencingThunk = createAsyncThunk(
    "AddGeoFencing/AddGeoFencingThunk",
    async ({name, geoFencingPoints}, { rejectWithValue }) => {
        try {
            const response = await axios.post(ApiEndpoints.GeoFencing.AddGeoFencing,
                {name, geoFencingPoints},
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                });
            return response.data;
        } catch (error) {
            console.log("error", error);
            const errorMessage = error?.response?.data?.message || "Something went wrong";
            return rejectWithValue(errorMessage);
        }
    }
);

const AddGeoFencingSlice = createSlice({
    name: "AddGeoFencing",
    initialState: {
        addGeoFencingData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAddGeoFencingData: (state, action) => {
            state.addGeoFencingData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [AddGeoFencingThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [AddGeoFencingThunk.fulfilled]: (state, action) => {
            state.addGeoFencingData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [AddGeoFencingThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.addGeoFencingData = [];
        },
    },
});

export default AddGeoFencingSlice.reducer;

export const { ResetAddGeoFencingData } = AddGeoFencingSlice.actions;

export { AddGeoFencingThunk };
