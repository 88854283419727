import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Card, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from "reactstrap";

import { Button, Block, Icon, BlockHead, BlockBetween, BlockHeadContent } from "../../../components/Component";
import { GetInstructorReviewsThunk } from "../../../redux/InstructorSlice/GetInstructorReviews";
import AddInstructorReviewModal from "./AddInstructorReviewModal";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { useParams } from "react-router";
import DeleteInstructorReviewModal from "./DeleteInstructorReviewModal";

const InstructorReviews = () => {
    const dispatch = useDispatch();
    const { instructorId } = useParams();


    const { loading, error, instructorReviewsData } = useSelector((state) => state.instructors.getInstructorReviews);
    const [reviewId, setReviewId] = useState();
    const [openModal, setOpenModal] = useState({
        add: false,
        delete: false
    })
    useEffect(() => {
        dispatch(GetInstructorReviewsThunk({ instructorId: instructorId }));
    }, [dispatch, instructorId]);
    return (
        <React.Fragment>
            <Head title="Instructor's Review"></Head>
            <Content>
                <Block>
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                            <Spinner />
                        </div>
                    ) : (<>
                        <BlockHead size="sm">
                            <BlockBetween>
                                <BlockHeadContent>
                                </BlockHeadContent>
                                <BlockHeadContent>
                                    <Button color="primary" onClick={() => setOpenModal({ add: true })}>
                                        <Icon name="plus" />
                                        <span>Add Review</span>
                                    </Button>
                                </BlockHeadContent>
                            </BlockBetween>
                        </BlockHead>
                        <Card className="card-bordered card-stretch">
                            <div className="card-inner-group">
                                <div className="card-inner">
                                    <div className="card-title-group">
                                        <div className="card-title">
                                            <h5 className="title">Student's Reviews</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-inner p-0">
                                    <table className="table table-tranx">
                                        {/* <thead>
                                            <tr className="tb-tnx-head">
                                                <th className="tb-tnx-info">
                                                    <span className="tb-tnx-desc d-none d-sm-inline-block">
                                                        <span>Student Name</span>
                                                    </span>
                                                    <span className="tb-tnx-date d-md-inline-block d-none">
                                                        <span className="d-none d-md-block">
                                                            <span>rating</span>
                                                            <span>Reviews</span>
                                                        </span>
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead> */}
                                        <thead>
                                            <tr className="tb-tnx-head">

                                                <th className="tb-tnx-info">
                                                    <span className="tb-tnx-desc d-none d-sm-inline-block">
                                                        <span>Student Name</span>
                                                    </span>
                                                </th>
                                                <th className="tb-tnx-id">
                                                    <span className="">Rating</span>
                                                </th>

                                                <th className="tb-tnx-amount is-alt">
                                                    <span className="tb-tnx-status d-none d-md-inline-block">Review</span>
                                                </th>
                                                <th className="tb-tnx-action">
                                                    <span>Action</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        {error ? (
                                            <div className="m-3">
                                                <span className="text-silent">No Review found</span>
                                            </div>
                                        ) : (
                                            <tbody>
                                                {instructorReviewsData?.length > 0
                                                    ? instructorReviewsData?.map((reviewDetail, index) => {
                                                        return (

                                                            <tr key={index} className="tb-tnx-item">
                                                                <td className="tb-tnx-info">
                                                                    <div className="tb-tnx-desc">
                                                                        <span className="title">{reviewDetail?.studentName}</span>
                                                                    </div>
                                                                </td>

                                                                <td className="tb-tnx-amount is-alt">
                                                                    <div className="tb-tnx-status d-none d-md-inline-block">
                                                                        {reviewDetail?.rating}
                                                                    </div>
                                                                </td>
                                                                <td className="tb-tnx-id">
                                                                    <span>{reviewDetail?.review}</span>
                                                                </td>

                                                                <td className="tb-tnx-action">
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                                                        >
                                                                            <Icon name="more-h"></Icon>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu right>
                                                                            <ul className="link-list-plain">
                                                                                <li>
                                                                                    <DropdownItem onClick={(ev) => { setOpenModal({ delete: true }); setReviewId(reviewDetail?._id) }}
                                                                                    >
                                                                                        Delete Review
                                                                                    </DropdownItem>
                                                                                </li>
                                                                            </ul>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                    : null}
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                            </div>
                        </Card>
                    </>
                    )}
                </Block>
            </Content>
            {openModal.add && <AddInstructorReviewModal openModal={openModal} setOpenModal={() => setOpenModal({ add: false })} instructorId={instructorId} />}
            {openModal.delete && <DeleteInstructorReviewModal openModal={openModal} setOpenModal={() => setOpenModal({ delete: false })} instructorId={instructorId} reviewId={reviewId} />}
        </React.Fragment>
    );
};

export default InstructorReviews;
