import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetInstructorScheduleThunk = createAsyncThunk(
  "GetInstructorSchedule/GetInstructorScheduleThunk",
  async ({ instructorId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${ApiEndpoints.InstructorsEndPoint.GetInstructorSchedule}?instructorId=${instructorId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const GetInstructorScheduleSlice = createSlice({
  name: "GetInstructorSchedule",
  initialState: {
    instructorScheduleData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetInstructorScheduleData: (state, action) => {
      state.instructorScheduleData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [GetInstructorScheduleThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GetInstructorScheduleThunk.fulfilled]: (state, action) => {
      state.instructorScheduleData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [GetInstructorScheduleThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.error = true;
      state.loading = false;
    },
  },
});

export default GetInstructorScheduleSlice.reducer;

export const { ResetInstructorScheduleData } = GetInstructorScheduleSlice.actions;

export { GetInstructorScheduleThunk };
