import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const DeleteInstructorReviewsThunk = createAsyncThunk(
    "DeleteInstructorReviews/DeleteInstructorReviewsThunk",
    async ({ reviewId }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiEndpoints.InstructorsEndPoint.DeleteInstructorReviews}?reviewId=${reviewId}`,
                {},
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                }
            );
            return response.data?.reviews;
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error.response.data);
        }
    }
);

const DeleteInstructorReviewsSlice = createSlice({
    name: "DeleteInstructorReviews",
    initialState: {
        deleteInstructorReviewsData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetDeleteInstructorReviewsData: (state, action) => {
            state.deleteInstructorReviewsData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [DeleteInstructorReviewsThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [DeleteInstructorReviewsThunk.fulfilled]: (state, action) => {
            state.deleteInstructorReviewsData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [DeleteInstructorReviewsThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.deleteInstructorReviewsData = {};

        },
    },
});

export default DeleteInstructorReviewsSlice.reducer;

export const { ResetDeleteInstructorReviewsData } = DeleteInstructorReviewsSlice.actions;

export { DeleteInstructorReviewsThunk };
