import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const AddLessonsThunk = createAsyncThunk(
  "AddLessons/AddLessonsThunk",
  async ({ isComplementary, packageName, numberOfLessons, studentId, instructorId, expiryDate, stripePID }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${ApiEndpoints.LesssonsEndPoint.AddStudentLessons}?studentId=${studentId}&instructorId=${instructorId}`,
        { lessons: numberOfLessons, expiryDate, stripePID, isComplementary, packageName },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const AddLessonsSlice = createSlice({
  name: "AddLessons",
  initialState: {
    lessonsData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetAddStudentLessonsData: (state, action) => {
      state.lessonsData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [AddLessonsThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [AddLessonsThunk.fulfilled]: (state, action) => {
      state.lessonsData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [AddLessonsThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default AddLessonsSlice.reducer;

export const { ResetAddStudentLessonsData } = AddLessonsSlice.actions;

export { AddLessonsThunk };
