import React from 'react'
import usePlacesAutocomplete from 'use-places-autocomplete';
import StudentLessons from '../StudentsLesson';
import { useLoadScript } from '@react-google-maps/api';
import { useEffect } from 'react';

const StudentLesson = () => {


    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ["places"],
      });
    
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
        init
      } = usePlacesAutocomplete({
        requestOptions: {
          location: { lat: () => 43.653225, lng: () => -79.383186 },
          radius: 200 * 1000,
          // componentRestrictions: { country: "ca" },
        },
      });

    useEffect(()=>{
        init();
    }, [isLoaded])
  return (
    <React.Fragment>
        {(isLoaded && ready) &&
            <StudentLessons setValue={setValue} clearSuggestions={clearSuggestions} value={value} ready={ready} status={status} data={data} />
        }
    </React.Fragment>
  )
}

export default StudentLesson;