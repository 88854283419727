import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const AddRegionThunk = createAsyncThunk(
    "AddRegion/AddRegionThunk",
    async ({ region, licenseTypes }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiEndpoints.Configuration.AddRegion}`,
                { region, licenseTypes },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                }
            );
            return response.data;
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error.response.data);
        }
    }
);

const AddRegionSlice = createSlice({
    name: "AddRegion",
    initialState: {
        addRegionData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAddRegionData: (state, action) => {
            state.addRegionData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },

    extraReducers: {
        [AddRegionThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [AddRegionThunk.fulfilled]: (state, action) => {
            state.addRegionData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [AddRegionThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
        },
    },
});

export default AddRegionSlice.reducer;

export const { ResetAddRegionData } = AddRegionSlice.actions;

export { AddRegionThunk };
