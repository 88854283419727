import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import { Spinner } from "reactstrap";
import { Block, Icon } from "../../../components/Component";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GetInstructorThunk } from "../../../redux/InstructorSlice/GetInstructorDetail";
import { useParams } from "react-router";
import moment from "moment";
import UpdateInstructorProfileModal from "./UpdateInstructorProfileModal";

const InstructorProfile = ({ setProfileName, setProfileEmail, setProfileImage }) => {
    const { instructorId } = useParams();
    const dispatch = useDispatch();
    const { loading, instructorData } = useSelector((state) => state.instructors.getInstructorDetail);

    const [modal, setModal] = useState({
        update: false
    });

    useEffect(() => {
        dispatch(GetInstructorThunk({ instructorId: instructorId }));
    }, [dispatch, instructorId]);

    useEffect(() => {
        if (Object.keys(instructorData).length !== 0) {
            setProfileName(instructorData?.fullName);
            setProfileEmail(instructorData?.email);
            setProfileImage(instructorData?.instructorImage);
        }
    }, [instructorData]);
    console.log('instructorData', instructorData)

    return (
        <React.Fragment>
            <Head title="User List - Profile"></Head>
            <br />
            <br />
            <br />
            <Block>
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                    <Spinner />
                    </div>
                ) : (
                    <>
                    {Object.keys(instructorData).length === 0 ? (
                        <p>No data Found</p>
                    ) : (
                        <div className="nk-data data-list">
                        <div className="data-head">
                            <h6 className="overline-title">Basics</h6>
                        </div>
                        <div className="data-item" onClick={() => setModal({ update: true })}>
                            <div className="data-col">
                            <span className="data-label">Full Name</span>
                            <span className="data-value">{instructorData?.fullName}</span>
                            </div>
                            <div className="data-col data-col-end">
                            <span className="data-more">
                                <Icon name="forward-ios"></Icon>
                            </span>
                            </div>
                        </div>
                        <div className="data-item" onClick={() => setModal({ update: true })}>
                            <div className="data-col">
                            <span className="data-label">Status</span>
                            <span
                                className={`data-value badge badge-dot badge-${instructorData.status === "Approved"
                                ? "success"
                                : instructorData.status === "Pending"
                                    ? "warning"
                                    : "danger"
                                }`}
                            >
                                {instructorData.status}
                            </span>
                            </div>
                            <div className="data-col data-col-end">
                            <span className="data-more">
                                <Icon name="forward-ios"></Icon>
                            </span>
                            </div>
                        </div>
                        <div className="data-item">
                            <div className="data-col">
                            <span className="data-label">Email</span>
                            <span className="data-value">{instructorData.email}</span>
                            </div>
                            <div className="data-col data-col-end">
                            <span className="data-more disable">
                                <Icon name="lock-alt"></Icon>
                            </span>
                            </div>
                        </div>
                        <div className="data-item" onClick={() => setModal({ update: true })}>
                            <div className="data-col">
                            <span className="data-label">Phone Number</span>
                            <span className="data-value text-soft">{instructorData?.phoneNumber}</span>
                            </div>
                            <div className="data-col data-col-end">
                            <span className="data-more">
                                <Icon name="forward-ios"></Icon>
                            </span>
                            </div>
                        </div>
                        <div className="data-item" onClick={() => setModal({ update: true })}>
                            <div className="data-col">
                            <span className="data-label">Address</span>
                            <span className="data-value">{instructorData?.address} </span>
                            </div>
                            <div className="data-col data-col-end">
                            <span className="data-more">
                                <Icon name="forward-ios"></Icon>
                            </span>
                            </div>
                        </div>
                        <div className="data-item" onClick={() => setModal({ update: true })}>
                            <div className="data-col">
                            <span className="data-label">State</span>
                            <span className="data-value">{instructorData?.state} </span>
                            </div>
                            <div className="data-col data-col-end">
                            <span className="data-more">
                                <Icon name="forward-ios"></Icon>
                            </span>
                            </div>
                        </div>
                        <div className="data-item" onClick={() => setModal({ update: true })}>
                            <div className="data-col">
                            <span className="data-label">Gender</span>
                            <span className="data-value">{instructorData?.gender} </span>
                            </div>
                            <div className="data-col data-col-end">
                            <span className="data-more">
                                <Icon name="forward-ios"></Icon>
                            </span>
                            </div>
                        </div>
                        <div className="data-item" onClick={() => setModal({ update: true })}>
                            <div className="data-col">
                            <span className="data-label">GST/HST Number</span>
                            <span className="data-value">{instructorData?.taxNumber} </span>
                            </div>
                            <div className="data-col data-col-end">
                            <span className="data-more">
                                <Icon name="forward-ios"></Icon>
                            </span>
                            </div>
                        </div>
                        <div className="data-item" onClick={() => setModal({ update: true })}>
                            <div className="data-col">
                            <span className="data-label">Rippling Employee Number</span>
                            <span className="data-value">{instructorData?.ripplingEmployeeNumber} </span>
                            </div>
                            <div className="data-col data-col-end">
                            <span className="data-more">
                                <Icon name="forward-ios"></Icon>
                            </span>
                            </div>
                        </div>
                        <div className="data-item" onClick={() => setModal({ update: true })}>
                            <div className="data-col">
                            <span className="data-label">Hourly Rate</span>
                            <span className="data-value">${instructorData?.baseRate} </span>
                            </div>
                            <div className="data-col data-col-end">
                            <span className="data-more">
                                <Icon name="forward-ios"></Icon>
                            </span>
                            </div>
                        </div>
                        <div className="data-item" onClick={() => setModal({ update: true })}>
                            <div className="data-col">
                            <span className="data-label">Collect Sales Tax</span>
                            <span className="data-value">{instructorData?.collectsTax ? "True" : "False" } </span>
                            </div>
                            <div className="data-col data-col-end">
                            <span className="data-more">
                                <Icon name="forward-ios"></Icon>
                            </span>
                            </div>
                        </div>
                        <div className="data-item" onClick={() => setModal({ update: true })}>
                            <div className="data-col">
                            <span className="data-label">Time Zone</span>
                            <span className="data-value">{instructorData?.timeZoneOffset === 0 ? "EST" : instructorData?.timeZoneOffset === 1 ? "CST" : instructorData?.timeZoneOffset === 2 ? "MST" : instructorData?.timeZoneOffset === 3 ? "PST" : ""} </span>
                            </div>
                            <div className="data-col data-col-end">
                            <span className="data-more">
                                <Icon name="forward-ios"></Icon>
                            </span>
                            </div>
                        </div>
        
                        <div className="data-item" onClick={() => setModal({ update: true })}>
                            <div className="data-col">
                            <span className="data-label">Bio</span>
                            <span className="data-value">{instructorData?.bio} </span>
                            </div>
                            <div className="data-col data-col-end">
                            <span className="data-more">
                                <Icon name="forward-ios"></Icon>
                            </span>
                            </div>
                        </div>
            
                        <div className="data-item" onClick={() => setModal({ update: true })}>
                            <div className="data-col">
                            <span className="data-label">Language</span>
                            <span className="data-value">{
                                instructorData?.languages?.map((language, index) => {
                                return (`${language} ${((index + 1) < instructorData?.languages?.length) ? ',' : ''}`);
                                })
                            }
                            </span>
                            </div>
                            <div className="data-col data-col-end">
                            <span className="data-more">
                                <Icon name="forward-ios"></Icon>
                            </span>
                            </div>
                        </div>
                        <div className="data-item" onClick={() => setModal({ update: true })}>
                            <div className="data-col">
                            <span className="data-label">Schedule Expiry Date</span>
                            <span className="data-value">{moment(instructorData?.scheduleExpiry).utcOffset(0, false).format("Do MMM YYYY")} </span>
                            </div>
                            <div className="data-col data-col-end">
                            <span className="data-more">
                                <Icon name="forward-ios"></Icon>
                            </span>
                            </div>
                        </div>
                        </div>
                    )}
                    </>
                )}
            </Block>

            {modal.update && <UpdateInstructorProfileModal modal={modal} setModal={()=> setModal({ update: false })}/> }
        </React.Fragment>
    );
}

export default InstructorProfile;
