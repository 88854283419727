import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const UpdateSeasonalPromotionStatusThunk = createAsyncThunk(
  "UpdateSeasonalPromotionStatus/UpdateSeasonalPromotionStatusThunk",
  async ({ isActive, promoId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${ApiEndpoints.PromotionsEndPoint.UpdateSeasonalPromotionStatus}?promoId=${promoId}`,
        {
          isActive,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const UpdateSeasonalPromotionStatusSlice = createSlice({
  name: "UpdateSeasonalPromotionStatus",
  initialState: {
    UpdateSeasonalPromotionStatusData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetUpdateSeasonalPromotionStatusData: (state, action) => {
      state.UpdateSeasonalPromotionStatusData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },

  extraReducers: {
    [UpdateSeasonalPromotionStatusThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [UpdateSeasonalPromotionStatusThunk.fulfilled]: (state, action) => {
      state.UpdateSeasonalPromotionStatusData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [UpdateSeasonalPromotionStatusThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default UpdateSeasonalPromotionStatusSlice.reducer;

export const { ResetUpdateSeasonalPromotionStatusData } = UpdateSeasonalPromotionStatusSlice.actions;

export { UpdateSeasonalPromotionStatusThunk };
