import React from 'react';
import { Button, Col, Form, Modal, ModalBody, Spinner } from 'reactstrap';
import { Icon } from '../../../components/Component';
import { useSelector, useDispatch } from 'react-redux';
import { ResetDeleteLessonData } from '../../../redux/LessonsSlice/DeleteStudentLessonSlice';
import toast from 'react-hot-toast';
import { GetStudentLessonsThunk } from '../../../redux/StudentsSlice/GetStudentLessonsSlice';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { MarkLessonAsMissedThunk, ResetMarkLessonAsMissedData } from '../../../redux/LessonsSlice/MarkLessonAsMissedSlice';

const ChangeLessonStatusModal = ({ modal, setModal, lessonId }) => {
    const dispatch = useDispatch();
    const { studentId } = useParams();
    const { loading, success, error, errorMessage } = useSelector((state) => state.lessons.markLessonAsMissed);

    const handleDeleteLesson = (params) => {
        dispatch(MarkLessonAsMissedThunk({ lessonId }))
    }

    if(success) {
        toast.success("Lesson has been marked as missed successfully.");
        dispatch(GetStudentLessonsThunk({ studentId }));
        dispatch(ResetMarkLessonAsMissedData())
        setModal({ markLessonAsMissed: false });
    }

    if(error){
        toast.error(errorMessage);
        dispatch(ResetMarkLessonAsMissedData());
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={modal.markLessonAsMissed}
                toggle={() => setModal({ markLessonAsMissed: false })}
                className="modal-dialog-centered"
                size="md"
            >
                <ModalBody>
                    <div
                        onClick={(ev) => {
                        ev.preventDefault();
                        setModal({ markLessonAsMissed: false });
                        }}
                        className="close cursor_pointer"
                    >
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h4 className="title">Mark Lesson As Missed</h4>
                        <div className="mt-4">
                            <Form className="row gy-4 mt-4 mb-5">
                                <Col md="12">
                                    <div>
                                        <span>Are you sure you want to mark lesson as missed?</span>
                                    </div>
                                </Col>
                                <Col size="12">
                                    <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button disabled={loading} onClick={(e) => handleDeleteLesson(e)} color="primary" size="md" type="submit">
                                                {loading ? <Spinner/> : "Mark Lesson As Missed"}
                                            </Button>
                                        </li>
                                        <li>
                                            <div
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    setModal({ markLessonAsMissed: false });
                                                }}
                                                className="link link-light cursor_pointer"
                                            >
                                                Cancel
                                            </div>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default ChangeLessonStatusModal;
