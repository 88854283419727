import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const AddInstructorOutOfOfficeThunk = createAsyncThunk(
    "AddInstructorOutOfOffice/AddInstructorOutOfOfficeThunk",
    async ({  startTime, endTime, instructorId, startDate, endDate }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiEndpoints.InstructorsEndPoint.AddInstructorOutOfOffice}?instructorId=${instructorId}`,
                {  startTime, endTime, startDate, endDate },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                }
            );
            return response.data?.Event;
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error.response.data.message);
        }
    }
);

const AddInstructorOutOfOfficeSlice = createSlice({
    name: "AddInstructorOutOfOffice",
    initialState: {
        addInstructorOutOfOfficeData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAddInstructorOutOfOfficeData: (state, action) => {
            state.addInstructorOutOfOfficeData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [AddInstructorOutOfOfficeThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [AddInstructorOutOfOfficeThunk.fulfilled]: (state, action) => {
            state.addInstructorOutOfOfficeData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [AddInstructorOutOfOfficeThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.addInstructorOutOfOfficeData = {};

        },
    },
});

export default AddInstructorOutOfOfficeSlice.reducer;

export const { ResetAddInstructorOutOfOfficeData } = AddInstructorOutOfOfficeSlice.actions;

export { AddInstructorOutOfOfficeThunk };
