import React, { useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { FormGroup, Form, Modal, ModalBody, Spinner } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  Button,
  PreviewAltCard,
  RSelect,
} from "../../components/Component";
import { Link, useParams } from "react-router-dom";
import { findUpper } from "../../utils/Utils";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  GetStudentInstructorsThunk,
} from "../../redux/StudentsSlice/GetStudentInstructorsSlice";
import { useSelector } from "react-redux";
import { GetInstructorListThunk } from "../../redux/InstructorSlice/GetInstructorListSlice";
import { ApiEndpoints } from "../../utils/ApiEndpoints";
import { AssignStudentInstructorThunk, ResetAssignStudentInstructorsData } from "../../redux/StudentsSlice/AssignStudentInstructorSlice";
import toast from "react-hot-toast";

const StudentInstructor = () => {
  const { studentId } = useParams();
  const dispatch = useDispatch();

  const { loading, studentInstructorsData } = useSelector((state) => state.Students.getStudentInstructors);
  const { 
    loading: AssignStudentInstructorLoading, 
    success: AssignStudentInstructorSuccess, 
    error: AssignStudentInstructorerror, 
    errorMessage: AssignStudentInstructorErrorMessage, 
    assignStudentInstructorData 
  } = useSelector((state) => state.Students.assignStudentInstructor);

  const { instructorsList } = useSelector((state) => state.instructors.getInstructorList);
  const [smOption, setSmOption] = useState(false);
  const [selectedInstructor, setSelectedInstructor] = useState();
  const [allInstructorList, setAllInstructorList] = useState([]);

  const [modal, setModal] = useState({
    add: false,
    edit: false,
  });

  // submit function to add a new item
  const onFormSubmit = (formData) => {
    dispatch(AssignStudentInstructorThunk({ studentId, instructorId: selectedInstructor }));
  };

  useEffect(() => {
    dispatch(GetStudentInstructorsThunk({ studentId }));
    dispatch(GetInstructorListThunk());
  }, [dispatch, studentId]);

  useEffect(() => {
    const list = instructorsList?.instructors?.map((instructor) => {
      return { label: instructor.fullName, value: instructor._id };
    });
    setAllInstructorList(list);
  }, [instructorsList]);

  const { handleSubmit } = useForm();

  if(AssignStudentInstructorSuccess) {
    toast.success("An instructor has been assigned successfully.");
    dispatch(ResetAssignStudentInstructorsData());
    dispatch(GetStudentInstructorsThunk({ studentId }));
    setModal({ add: false });
  }
  if(AssignStudentInstructorerror) {
    toast.error(AssignStudentInstructorErrorMessage);
    dispatch(ResetAssignStudentInstructorsData());
    setModal({ add: false });
  }

  return (
    <React.Fragment>
      <Head title="User Contact - Card"></Head>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <Spinner />
        </div>
      ) : (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Assigned Instructors</BlockTitle>
                <BlockDes className="text-soft">
                  <p>You have total {studentInstructorsData?.instructors?.length} assigned instructor.</p>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    href="#toggle"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setSmOption(!smOption);
                    }}
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                  >
                    <Icon name="menu-alt-r"></Icon>
                  </a>
                  <div className="toggle-expand-content" style={{ display: smOption ? "block" : "none" }}>
                    <ul className="nk-block-tools g-3">
                      <li className="nk-block-tools-opt">
                        <Button color="primary" onClick={() => setModal({ add: true })}>
                          <Icon name="plus" />
                          <span>Assign Instructor</span>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Row className="g-gs">
              {studentInstructorsData?.instructors?.map((instructor) => {
                return (
                  <Col sm="12" key={instructor._id}>
                    <PreviewAltCard>
                      <div className="team">
                        <div className={`team-status bg-success text-white`}>
                          <Icon name={`check-thick`}></Icon>
                        </div>

                        <div className="user-card user-card-s2">
                          <UserAvatar
                            theme="purple"
                            className="md"
                            text={findUpper(instructor.fullName)}
                            image={`${ApiEndpoints.GetImage}/instructors/${instructor.instructorImage}`}
                          >
                            <div className="status dot dot-lg dot-success"></div>
                          </UserAvatar>
                          <div className="user-info">
                            <h6>{instructor.fullName}</h6>
                            <span className="sub-text">{instructor.email.toLowerCase()}</span>
                          </div>
                        </div>
                        <div className="team-statistics px-5" style={{ maxWidth: "750px !important" }}>
                          <p>{instructor.bio}</p>
                        </div>
                        <ul className="team-statistics">
                          <li>
                            <span>{instructor.phoneNumber}</span>
                            <span>Phone Number</span>
                          </li>
                          <li>
                            <span>{instructor.gender ? instructor.gender : "-"}</span>
                            <span>Gender</span>
                          </li>
                          <li>
                            <span>{instructor.serviceAreas[0]}</span>
                            <span>ServiceAreas</span>
                          </li>
                        </ul>
                        <div className="team-view">
                          <Link to={`/instructors/${instructor._id}`}>
                            <Button outline color="light" className="btn-round w-150px">
                              <span>View Profile</span>
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </PreviewAltCard>
                  </Col>
                );
              })}
            </Row>
          </Block>

          <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
            <ModalBody>
              <div
                onClick={(ev) => {
                  ev.preventDefault();
                  setModal({ add: false });
                }}
                className="close cursor_pointer"
              >
                <Icon name="cross-sm"></Icon>
              </div>
              <div className="p-2">
                <h5 className="title">Assign Instructor</h5>
                <div className="mt-4">
                  <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                    <Col md="12">
                      <FormGroup>
                        <label className="form-label">Name</label>
                        <RSelect
                          options={allInstructorList}
                          name="name"
                          onChange={(e) => setSelectedInstructor(e.value)}
                          placeholder="Choose Instructor"
                        />
                      </FormGroup>
                    </Col>
                    <Col size="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button disabled={AssignStudentInstructorLoading} type="submit" color="primary" size="md">
                            {AssignStudentInstructorLoading ? <Spinner /> : "Assign Instructor"}
                          </Button>
                        </li>
                        <li>
                          <Button
                            onClick={(ev) => {
                              ev.preventDefault();
                              setModal({ add: false });
                            }}
                            className="link link-light"
                          >
                            Cancel
                          </Button>
                        </li>
                      </ul>
                    </Col>
                  </Form>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </Content>
      )}
    </React.Fragment>
  );
};
export default StudentInstructor;
