import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// add student
const UpdatePickupPointsThunk = createAsyncThunk(
  "UpdatePickupPoints/UpdatePickupPointsThunk",
  async ({ name, city, logo, image, latitude, longitude, pickupAddress, locationId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${`${ApiEndpoints.PickupPoints.UpdatePickupPoint}?locationId=${locationId}`}`,
        {
          name,
          city,
          logo,
          image,
          latitude,
          longitude,
          pickupAddress,
          locationId
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const UpdatePickupPointsSlice = createSlice({
  name: "UpdatePickupPoints",
  initialState: {
    updatePickupPointData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetUpdatePickupPointsData: (state, action) => {
      state.updatePickupPointData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [UpdatePickupPointsThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [UpdatePickupPointsThunk.fulfilled]: (state, action) => {
      state.updatePickupPointData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [UpdatePickupPointsThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
      state.updatePickupPointData = [];
    },
  },
});

export default UpdatePickupPointsSlice.reducer;

export const { ResetUpdatePickupPointsData } = UpdatePickupPointsSlice.actions;

export { UpdatePickupPointsThunk };
