import React, { useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import CalenderApp from "../../components/partials/calender/Calender";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewAltCard,
} from "../../components/Component";

import { useDispatch, useSelector } from "react-redux";
import { GetStudentLessonsThunk } from "../../redux/StudentsSlice/GetStudentLessonsSlice";
import { Spinner } from "reactstrap/es";
import StudentCalender from "./Calender/StudentCalender";

var today = new Date();

var tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);

var yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);

const StudentSchedule = ({ studentId }) => {
  const dispatch = useDispatch();

  const { loading, studentLessonsData } = useSelector((state) => state.Students.getStudentLessons);
  const [mockEvents, updateEvent] = useState(studentLessonsData?.lessons);

  useEffect(() => {
    dispatch(GetStudentLessonsThunk({ studentId }));
  }, []);

  useEffect(() => {
    if (studentLessonsData?.lessons?.length !== 0) {
      var newArr = studentLessonsData?.lessons?.filter((lesson, index) => {
        if (lesson.status !== "Awaiting Scheduling") return lesson;
      });
      newArr = newArr?.map((lesson) => {
        let colorName = "";
        if (lesson.status === "Scheduled") colorName = "fc-event-info";
        else if (lesson.status === "Completed") colorName = "fc-event-success";
        return {
          ...lesson,
          id: "default-event-id-" + lesson._id,
          title: "Lesson  " + lesson.studentLessonNumber,
          start: lesson.dateTime,
          end: lesson.dateTime,
          className: colorName,
          type: { value: colorName, label: lesson.status },
          description: "Use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden.",
        };
      });

      updateEvent(newArr);
    }
  }, [studentLessonsData]);

  const editEvent = (formData) => {};

  const deleteEvent = (id) => {
    let filteredEvents = mockEvents.filter((item) => item.id !== id);
    updateEvent(filteredEvents);
  };

  return (
    <React.Fragment>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <Spinner />
        </div>
      ) : (
        <Content>
          <Head title="Calender" />
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Student Schedule</BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <PreviewAltCard>
              <StudentCalender lessonsDetail={mockEvents} onDelete={deleteEvent} onEdit={editEvent} />
            </PreviewAltCard>
          </Block>
        </Content>
      )}
    </React.Fragment>
  );
};
export default StudentSchedule;
