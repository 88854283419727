import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const AssignStudentInstructorThunk = createAsyncThunk(
    "AssignStudentInstructor/AssignStudentInstructorThunk",
    async ({ studentId, instructorId }, { rejectWithValue }) => {
        try {
        const response = await axios.post(
            `${ApiEndpoints.StudentsEndPoint.AssignNewStudentInstructor}?studentId=${studentId}&instructorId=${instructorId}`,
            {},
            {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            }
        );
        return response.data;
        } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
        }
    }
);

const AssignStudentInstructorSlice = createSlice({
    name: "AssignStudentInstructor",
    initialState: {
        assignStudentInstructorData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAssignStudentInstructorsData: (state, action) => {
            state.success = false;
            state.error = false;
            state.loading = false;
        },
    },
    extraReducers: {
        [AssignStudentInstructorThunk.pending]: (state, action) => {
        state.loading = true;
        state.success = false;
        state.assignInstructorError = false;
        },
        [AssignStudentInstructorThunk.fulfilled]: (state, action) => {
        state.studentInstructorsData = action.payload;
        state.success = true;
        state.loading = false;
        },
        [AssignStudentInstructorThunk.rejected]: (state, action) => {
        state.errorMessage = action.payload.message;
        state.success = false;
        state.assignInstructorError = true;
        state.loading = false;
        },
    },
});

export default AssignStudentInstructorSlice.reducer;

export const { ResetAssignStudentInstructorsData } = AssignStudentInstructorSlice.actions;

export { AssignStudentInstructorThunk };
