import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const AssignInstructorGeoFencingThunk = createAsyncThunk(
  "AssignInstructorGeoFencing/AssignInstructorGeoFencingThunk",
  async ({ instructorId,  geoFencingId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${ApiEndpoints.InstructorsEndPoint.AssignInstructorGeoFencing}?instructorId=${instructorId}`,
        {
            geoFencingId: [geoFencingId],
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong";
        return rejectWithValue(errorMessage);
    }
  }
);

const AssignInstructorGeoFencingSlice = createSlice({
  name: "AssignInstructorGeoFencing",
  initialState: {
    assignInstructorGeoFencingData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetAssignInstructorGeoFencing: (state, action) => {
      state.assignInstructorGeoFencingData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [AssignInstructorGeoFencingThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [AssignInstructorGeoFencingThunk.fulfilled]: (state, action) => {
      state.assignInstructorGeoFencingData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [AssignInstructorGeoFencingThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.error = true;
      state.loading = false;
      state.assignInstructorGeoFencingData = [];
    },
  },
});

export default AssignInstructorGeoFencingSlice.reducer;

export const { ResetAssignInstructorGeoFencing } = AssignInstructorGeoFencingSlice.actions;

export { AssignInstructorGeoFencingThunk };
