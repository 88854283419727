import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const ProfileThunk = createAsyncThunk("Profile/ProfileThunk", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(ApiEndpoints.Profile, {
      headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
    });

    return response.data.data;
  } catch (error) {
    console.log("error", error);
    return rejectWithValue(error.response.data);
  }
});

const ProfileSlice = createSlice({
  name: "Profile",
  initialState: {
    profileData: "",
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
    adminData: {},
  },
  reducers: {
    ResetProfileData: (state, action) => {
      state.profileData = "";
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [ProfileThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [ProfileThunk.fulfilled]: (state, action) => {
      state.profileData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [ProfileThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default ProfileSlice.reducer;

export const { ResetAllStudentData } = ProfileSlice.actions;

export { ProfileThunk };
