import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../../utils/ApiEndpoints";

// Remove In-Car Sheet
const GenerateInCarSheetThunk = createAsyncThunk(
    "GenerateInCarSheet/GenerateInCarSheetThunk",
    async ({ studentId }, { rejectWithValue }) => {
        try {
        const response = await axios.get(`${ApiEndpoints.StudentsEndPoint.inCarSheet.GenerateInCarSheet}?studentId=${studentId}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        });
        return response.data;
        } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
        }
    }
);

const GenerateInCarSheetsSlice = createSlice({
    name: "GenerateInCarSheets",
    initialState: {
        GenerateInCarSheetData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetGenerateInCarSheetData: (state, action) => {
        state.GenerateInCarSheetData = [];
        state.success = false;
        state.error = false;
        state.loading = false;
        state.errorMessage = "";
        },
    },
    extraReducers: {
        [GenerateInCarSheetThunk.pending]: (state, action) => {
        state.loading = true;
        state.success = false;
        state.error = false;
        },
        [GenerateInCarSheetThunk.fulfilled]: (state, action) => {
        state.GenerateInCarSheetData = action.payload;
        state.success = true;
        state.loading = false;
        },
        [GenerateInCarSheetThunk.rejected]: (state, action) => {
        state.errorMessage = action.payload.message;
        state.error = true;
        state.loading = false;
        },
    },
});

export default GenerateInCarSheetsSlice.reducer;

export const { ResetGenerateInCarSheetData } = GenerateInCarSheetsSlice.actions;

export { GenerateInCarSheetThunk };
