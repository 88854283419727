import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// add student
const EmailInvoiceThunk = createAsyncThunk(
    "EmailInvoice/EmailInvoiceThunk",
    async ({ invoiceId }, { rejectWithValue }) => {
        try {
        const response = await axios.post(`${ApiEndpoints.Invoices.EmailInvoice}?invoiceId=${invoiceId}`, 
        {},
        {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        });

        return response.data;
        } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong";
        return rejectWithValue(errorMessage);
        }
    }
);

const EmailInvoiceSlice = createSlice({
  name: "EmailInvoice",
  initialState: {
    EmailInvoiceData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetEmailInvoiceData: (state, action) => {
      state.EmailInvoiceData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [EmailInvoiceThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [EmailInvoiceThunk.fulfilled]: (state, action) => {
      state.EmailInvoiceData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [EmailInvoiceThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.error = true;
      state.loading = false;
    },
  },
});

export default EmailInvoiceSlice.reducer;

export const { ResetEmailInvoiceData } = EmailInvoiceSlice.actions;

export { EmailInvoiceThunk };
