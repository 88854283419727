import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// add student
const EditStudentProfileThunk = createAsyncThunk(
  "EditStudentProfile/EditStudentProfileThunk",
  async ({ fullName, email, phoneNumber, studentId, homePickup, postalCode }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${ApiEndpoints.StudentsEndPoint.EditStudentProfile}?studentId=${studentId}`,
        { fullName, email, phoneNumber, homePickup, postalCode },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const EditStudentProfilesSlice = createSlice({
  name: "EditStudentProfiles",
  initialState: {
    EditStudentProfileData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetEditStudentProfileData: (state, action) => {
      state.EditStudentProfileData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [EditStudentProfileThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [EditStudentProfileThunk.fulfilled]: (state, action) => {
      state.EditStudentProfileData = [...state.EditStudentProfileData, action.payload.data];
      state.success = true;
      state.loading = false;
    },
    [EditStudentProfileThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default EditStudentProfilesSlice.reducer;

export const { ResetEditStudentProfileData } = EditStudentProfilesSlice.actions;

export { EditStudentProfileThunk };
