import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-hot-toast";
import { Button, Col, Form, FormGroup, Modal, ModalBody, Spinner } from "reactstrap";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  RSelect,
} from "../../components/Component";
import {
  AssignPickupPointThunk,
  ResetAssignPickupPoint,
} from "../../redux/InstructorSlice/AssignInstructorPickupPoint";
import { GetInstructorPickupLocationThunk } from "../../redux/InstructorSlice/GetInstructorPickupLocations";
import {
  ResetUnAssignPickupPoint,
  UnAssignPickupPointThunk,
} from "../../redux/InstructorSlice/UnassignedPickupPointsSlice";
import { GetAllPickupPointsThunk } from "../../redux/PickupPoints/GetAllPickupPoints";
import { Formik } from 'formik';

const InstructorPickupLocations = () => {
  const { instructorId } = useParams();
  const dispatch = useDispatch();

  const { loading, instructorPickupLocationData } = useSelector((state) => state.instructors.getInstructorPickupPoints);
  const [pickupPointsList, setPickupPointsList] = useState([]);
  const [selectPickupPoint, setselectPickupPoint] = useState([]);

  const { loading: allPickupPointLoading, pickupPointsData } = useSelector(
    (state) => state.pickupPoints.getAllPickupPoints
  );

  const {
    loading: assignPickupPointLoading,
    success: assignPickupPointSuccess,
    error: assignPickupPointError,
  } = useSelector((state) => state.instructors.assignPickupPoint);

  const {
    loading: UnassignPickupPointLoading,
    success: UnassignPickupPointSuccess,
    error: UnassignPickupPointError,
    errorMessage: UnassignPickupPointErrorMessage,
  } = useSelector((state) => state.instructors.unAssignedPickupPoint);

  useEffect(() => {
    dispatch(GetInstructorPickupLocationThunk({ instructorId: instructorId }));
    dispatch(GetAllPickupPointsThunk());
  }, []);

  useEffect(() => {
    if (pickupPointsData?.pickupLocations?.length !== 0) {
      const list = pickupPointsData?.pickupLocations?.map((pickupPoint, index) => {
        return {
          label: `(${pickupPoint?.name}) - (${pickupPoint?.city})  - ${pickupPoint?.address?.location}`,
          value: pickupPoint._id,
        };
      });
      setPickupPointsList(list);
    }
  }, [pickupPointsData]);

  const [openDetails, setOpenDetails] = useState(false);
  const [modal, setModal] = useState({
    delete: false
  });
  

  const [selectedPickupPoint, SetSelectedPickupPoint] = useState();

  // submit function to add a new item
  const onFormSubmit = () => {
    dispatch(AssignPickupPointThunk({ instructorId, locationIds: selectedPickupPoint }));
  };

  const handleUnAssignPickupLocations = (e) => { 
    e.preventDefault();
    dispatch(
      UnAssignPickupPointThunk({ instructorId: instructorId, locationIds: selectPickupPoint })
    );
  }

  if (assignPickupPointSuccess) {
    dispatch(ResetAssignPickupPoint());
    dispatch(GetInstructorPickupLocationThunk({ instructorId: instructorId }));
    toast.success("Pickup point has been assigned successfully.");
    setOpenDetails(!openDetails);
  }

  if (UnassignPickupPointSuccess) {
    dispatch(ResetUnAssignPickupPoint());
    dispatch(GetInstructorPickupLocationThunk({ instructorId: instructorId }));
    toast.success("Pickup point has been unAssigned successfully.");
    setModal({delete: false})
  }

  if (assignPickupPointError) {
    dispatch(ResetAssignPickupPoint());
    toast.error("Opps! something went wrong. Please try again.");
    setOpenDetails(!openDetails);
  }

  if (UnassignPickupPointError) {
    toast.error(UnassignPickupPointErrorMessage);
    dispatch(ResetUnAssignPickupPoint());
    setModal({delete: false});
  }

  const { handleSubmit } = useForm();

  return (
    <div>
      {loading && allPickupPointLoading && UnassignPickupPointLoading ? (
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ height: "70vh", maxWidth: "100%" }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Instructor's Pickup Points</BlockTitle>
                <BlockDes className="text-soft">
                  <p>You have total {instructorPickupLocationData?.instructorPickupLocations?.length} pickup points.</p>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <Button color="primary" onClick={() => setOpenDetails(!openDetails)}>
                  <Icon name="plus" />
                  <span>Assign Pickup Point</span>
                </Button>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <table className="table table-ulogs">
            <thead className="thead-light">
              <tr>
                <th className="tb-col-os">
                  <span className="overline-title">Name</span>
                </th>
                <th className="tb-col-ip">
                  <span className="overline-title">City</span>
                </th>
                <th className="tb-col-time">
                  <span className="overline-title">Address</span>
                </th>
                <th className="tb-col-action">
                  <span className="overline-title">&nbsp;</span>
                </th>
              </tr>
            </thead>

            <tbody>
              {instructorPickupLocationData?.instructorPickupLocations?.map((pickupPoint, index) => {
                return (
                  <tr key={index}>
                    <td className="tb-col-os">{pickupPoint.name}</td>
                    <td className="tb-col-ip">
                      <span className="sub-text">{pickupPoint.city}</span>
                    </td>
                    <td className="tb-col-time">
                      <span className="sub-text">
                        {pickupPoint.date}{" "}
                        <span className="d-none d-sm-inline-block">{pickupPoint.address.location}</span>
                      </span>
                    </td>
                    <td className="tb-col-action">
                      {/* {pickupPoint.action === true && ( */}
                      <div
                        onClick={(ev) => {
                          ev.preventDefault();
                          setModal({ delete:true });
                          setselectPickupPoint(pickupPoint._id);
                          // dispatch(
                          //   UnAssignPickupPointThunk({ instructorId: instructorId, locationIds: pickupPoint._id })
                          // );
                        }}
                        className="link-cross mr-sm-n1 cursor_pointer"
                      >
                        <Icon name="cross" />
                      </div>
                      {/* )} */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <Modal
            isOpen={openDetails}
            toggle={() => setOpenDetails(!openDetails)}
            className="modal-dialog-centered"
            size="lg"
          >
            <ModalBody>
              <div
                onClick={(ev) => {
                  ev.preventDefault();
                  setOpenDetails(!openDetails);
                }}
                className="close cursor_pointer"
              >
                <Icon name="cross-sm"></Icon>
              </div>
              <div className="p-2">
                <h5 className="title">Assign Pickup Location</h5>
                <div className="mt-4">
                  <Form className="row gy-4 mt-4" onSubmit={handleSubmit(onFormSubmit)}>
                    <Col md="12">
                      <FormGroup>
                        <label className="form-label">Select pickup location</label>
                        <div className="form-control-wrap">
                          <RSelect options={pickupPointsList} onChange={(e) => SetSelectedPickupPoint(e.value)} />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col size="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button color="primary" size="md" type="submit">
                            {assignPickupPointLoading ? <Spinner size="sm" /> : "Add Pickup point"}
                          </Button>
                        </li>
                        <li>
                          <div
                            onClick={(ev) => {
                              ev.preventDefault();
                              setOpenDetails(!openDetails);
                            }}
                            className="link link-light cursor_pointer"
                          >
                            Cancel
                          </div>
                        </li>
                      </ul>
                    </Col>
                  </Form>
                </div>
              </div>
            </ModalBody>
          </Modal>

          <Modal isOpen={modal.delete} toggle={() => setModal({ delete: false })} className="modal-dialog-centered" size="md">
                    <ModalBody>
                        <div onClick={(ev) => { ev.preventDefault(); setModal({ delete: false }); }} className="close cursor_pointer">
                            <Icon name="cross-sm"></Icon>
                        </div>
                        <div className="p-2">
                            <h4 className="title">Remove Instructor's Pickup Points</h4>
                            <div className="mt-4">
                                <Formik>
                                    <Form className="row gy-4 mt-4 mb-5">
                                        <Col md="12">
                                            <div>
                                                <h6>Are you sure you want to remove?</h6>
                                            </div>
                                        </Col>
                                        <Col size="12">
                                            <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <Button disabled={UnassignPickupPointLoading} onClick={handleUnAssignPickupLocations} color="danger" size="md" type="submit">
                                                        <span>{UnassignPickupPointLoading ? <Spinner size="sm" /> : "Remove Pickup Points"} </span>
                                                    </Button>
                                                </li>
                                                <li>
                                                    <div onClick={(ev) => { ev.preventDefault(); setModal({ delete: false }); }} className="link link-light">
                                                        Cancel
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
        </>
      )}
    </div>
  );
};

export default InstructorPickupLocations;
