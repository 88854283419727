import React from 'react'
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Card, CardBody, CardText, CardTitle, Spinner } from 'reactstrap';
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle } from '../../../components/Component';

const ShowInstructorWeeklySlots = ({setSelectedDay, handleDaySchedule, modal, setModal}) => {
    const { loading, instructorScheduleData } = useSelector((state) => state.instructors.getInstructorSchedule);

    return (
        <React.Fragment>
            <BlockHead size="sm">
                <BlockBetween>
                <BlockHeadContent>
                    <BlockTitle page>Instructor Schedule</BlockTitle>
                </BlockHeadContent>
                </BlockBetween>
            </BlockHead>
            {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                <Spinner />
            </div>
            ) : (
                instructorScheduleData?.data?.map((daySchedule) => {

                    const daySlots = [];

                    let start = null
                    daySchedule?.data.map((obj, index) => {
                        const nextObj = daySchedule?.data[index+1]
                        const discontinuousStartTime = moment(nextObj?.startTime).utc().diff(
                            moment(obj?.endTime).utc())>0
                        const discontinuousWeeklyBool = nextObj?.weekly != obj?.weekly
                        const continuousLocations = nextObj?.locations.every(value => obj.locations.includes(value)) &&
                        obj.locations.every(value => nextObj?.locations.includes(value));

                        if(obj.weekly) {
                            if(start === null)
                                start = obj.startTime
                            if(discontinuousStartTime || discontinuousWeeklyBool || !continuousLocations){
                                daySlots.push({
                                    startTime: start,
                                    endTime: obj.endTime,
                                    weekly: obj.weekly,  
                                    locations: obj.locations,
                                });
                                start = null
                            }
                        }
                    });


                    // const daySlots = [];
                    // var breakerIndex = 0;
                    // for (let index = 0; index < daySchedule?.data?.length; index++) {
                    //     console.log('breakerIndex', breakerIndex)
                    //     console.log('daySlots', daySlots)
                    //     if(daySchedule?.data[index].weekly) {
                    //         if (index + 1 !== daySchedule?.data.length) {
                    //             var ms = moment(daySchedule?.data[index].endTime).diff(
                    //                 moment(daySchedule?.data[index + 1].startTime)
                    //             );
                    //             console.log('ms', ms)
                    //             if (Number(ms) !== 0) {
                    //                 console.log("coming inside =>")
                    //                 daySlots.push({
                    //                     startTime: daySchedule?.data[breakerIndex].startTime,
                    //                     endTime: daySchedule?.data[index].endTime,
                    //                 });
                    //                 breakerIndex = index + 1;
                    //             }
                    //         } else {
                    //             daySlots.push({
                    //             startTime: daySchedule?.data[breakerIndex].startTime,
                    //             endTime: daySchedule?.data[index].endTime,
                    //             });
                    //         }
                    //     } else {

                    //     }
                    // }
                    console.log('daySlots', daySlots)
                    return (
                    <Link
                        className="mb-2"
                        onClick={() => {
                        setSelectedDay(daySchedule.day);
                        handleDaySchedule({
                            dayTimeSlot: daySchedule?.data,
                            day: daySchedule?.day,
                        });
                        }}
                    >
                        <Card className="card-bordered w-100 mb-1">
                        <CardBody className="card-inner">
                            <CardTitle tag="h5">{daySchedule.day.toUpperCase()}</CardTitle>
                            {daySlots.map((slot, index) => {
                            return (
                                <>
                                <CardText tag="p" style={{ color: "#8094ae", marginBottom: "0.2rem" }}>
                                    {moment.utc(slot.startTime).format("hh:mm A")} -{" "}
                                    {moment.utc(slot.endTime).format("hh:mm A")}
                                </CardText>
                                </>
                            );
                            })}
                        </CardBody>
                        </Card>
                    </Link>
                    );
                })
            )}

        </React.Fragment>
    )
}

export default ShowInstructorWeeklySlots;