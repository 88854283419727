import React, { useEffect, useState } from 'react'
import ReactImageUploading from 'react-images-uploading';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, Spinner, FormGroup } from 'reactstrap';
import { useParams } from "react-router";
import { useSelector } from 'react-redux';

import { Icon, Row, Col, Button, RSelect } from "../../../components/Component";
import { GetInstructorThunk } from "../../../redux/InstructorSlice/GetInstructorDetail";
import { EditInstructorProfileThunk, ResetEditInstructorProfile } from "../../../redux/InstructorSlice/EditInstructorProfileSlice";
import { ApiEndpoints } from "../../../utils/ApiEndpoints";
import toast from 'react-hot-toast';
import axios from 'axios';
import { useForm } from 'react-hook-form';

const statusOptions = [
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
    { value: "Pending", label: "Pending" },
    { value: "Blocked", label: "Blocked" },
];

const checkMark = [
    { value: "true", label: "True" },
    { value: "false", label: "False" },
];
const timeZoneOffsetOptions = [
    { value: 0, label: "EST" },
    { value: 1, label: "CST" },
    { value: 2, label: "MST" },
    { value: 3, label: "PST" },
];

const UpdateInstructorProfileModal = ({ modal, setModal }) => {
    const { instructorId } = useParams();
    const dispatch = useDispatch();

    const { loading, instructorData } = useSelector((state) => state.instructors.getInstructorDetail);
    const {
        loading: UpdateInstructorProfileLoading,
        success: UpdateInstructorProfileSuccess,
        error: UpdateInstructorProfileError,
        errorMessage: UpdateInstructorProfileErrorMessage,
    } = useSelector((state) => state.instructors.editInstructorProfile);

    const [status, setStatus] = useState("");
    const [collectSalestax, setCollectSalestax] = useState("true");
    const [timeZoneOffset, setTimeZoneOffset] = useState(0);
    const [inputFieldData, setInputFieldData] = useState("");
    const [serviceAreas, setServiceAreas] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [images, setImages] = useState([]);
    const [LogoImageLoading, setLogoImageLoading] = useState(false);
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phoneNumber: "",
        baseRate: "",
        taxNumber: "",
        status: "",
        address: "",
        state: "",
        country: "",
        gender: "",
        bio: "",
        timeZoneOffset: "",
        instructorImage: "",
        ripplingEmployeeNumber: "",
        scheduleExpiry: new Date()
    });

    console.log('timeZoneOffset', timeZoneOffset)
    useEffect(() => {
        dispatch(GetInstructorThunk({ instructorId: instructorId }));
    }, [dispatch, instructorId]);

    useEffect(() => {
        if (Object.keys(instructorData).length !== 0) {
            // setProfileName(instructorData?.fullName);
            // setProfileEmail(instructorData?.email);
            // setProfileImage(instructorData?.instructorImage);
            setServiceAreas(instructorData?.serviceAreas);
            setLanguages(instructorData?.languages);
            setFormData({ ...instructorData });
            setCollectSalestax(instructorData?.collectsTax)
            setTimeZoneOffset(instructorData?.timeZoneOffset)
        }
    }, [instructorData]);

    const onChange = (imageList) => {
        setImages(imageList);
    };

    const onInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleLogoImageUploader = async (e) => {
        e.preventDefault();
        const formDt = new FormData();
        if (Number((e.target.files[0].size) / 1024) > 1000) {
            toast.error("Image size exceeding 1MB, please upload a smaller image");
        } else {
            setLogoImageLoading(true);
            formDt.append("image", e.target.files[0]);
            try {
            const response = await axios.post(`${ApiEndpoints.UploadImages}/instructors?instructorId=${instructorId}`, formDt, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            setFormData({ ...formData, instructorImage: response.data.imageName });
            setLogoImageLoading(false);
            } catch (error) {
            setLogoImageLoading(false);
            }
        }
    };

    const handleEditInstructorProfile = (e) => {
        e.preventDefault();
        console.log('timeZoneOffset', timeZoneOffset)
        dispatch(
            EditInstructorProfileThunk({
                fullName: formData.fullName,
                email: formData.email,
                phoneNumber: formData.phoneNumber,
                state: formData.state,
                country: formData.country,
                address: formData.address,
                ripplingEmployeeNumber: formData?.ripplingEmployeeNumber,
                status,
                instructorId,
                serviceAreas,
                gender: formData?.gender,
                bio: formData?.bio,
                languages,
                instructorImage: formData?.instructorImage,
                baseRate: formData?.baseRate,
                taxNumber: formData?.taxNumber,
                collectsTax: collectSalestax,
                timeZoneOffset
            })
        );
    };

    const { errors } = useForm();

    if (UpdateInstructorProfileSuccess) {
        toast.success("Profile has been edited successfully.");
        dispatch(ResetEditInstructorProfile());
        dispatch(GetInstructorThunk({ instructorId: instructorId }));
        setModal(false);
    }

    if (UpdateInstructorProfileError) {
        toast.error(UpdateInstructorProfileErrorMessage);
        dispatch(ResetEditInstructorProfile());
        setModal(false);
    }

    return (
        <React.Fragment>
            <Modal isOpen={modal.update} className="modal-dialog-centered" size="lg" toggle={() => setModal()}>
                <ModalBody>
                    <div
                        onClick={(ev) => {
                            ev.preventDefault();
                            setModal();
                        }}
                        className="close cursor_pointer"
                    >
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                    <h5 className="title mb-5">Update Profile</h5>
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "40vh" }}>
                            <Spinner />
                        </div>
                    ) : (
                        <div className="tab-content px-3">
                            <div className={`tab-pane active`} id="personal">
                                <Row className="gy-4">
                                    <form>
                                        <Row className="gy-4">
                                        <Col md="3">
                                            <FormGroup>
                                            <ReactImageUploading
                                                className="form-control"
                                                multiple
                                                value={images}
                                                onChange={onChange}
                                                maxNumber={10}
                                                dataURLKey="data_url"
                                            >
                                                {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                                                <div className="upload__image-wrapper">
                
                                                    <div className="row image-item">
                                                    <Row>
                                                        {/* {imageList.map((image, index) => ( */}
                                                        <Col className="m-2" md="12">
                                                        <img src={`${ApiEndpoints.GetImage}/instructors/${formData?.instructorImage}`} style={{ borderRadius: "5%" }} alt="" width="120" height="100" />
                                                        </Col>
                                                        {/* ))} */}
                                                    </Row>
                                                    </div>
                                                </div>
                                                )}
                                            </ReactImageUploading>
                
                                            </FormGroup>
                                        </Col>
                                        <Col md="9">
                                            <FormGroup className="mt-3">
                                            <label className="form-label">Profile Image</label>
                                            <input
                                                className="form-control"
                                                style={{ cursor: "pointer" }}
                                                // ref={register({ required: "This field is required" })}
                                                type="file"
                                                name="logo"
                                                // defaultValue={formData?.instructorImage}
                                                onChange={(e) => handleLogoImageUploader(e)}
                                            />
                                            {LogoImageLoading && <Spinner />}
                                            {errors.logo && <span className="invalid">{errors.logo.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                            <label className="form-label" htmlFor="fullName">
                                                Full Name
                                            </label>
                                            <input
                                                type="text"
                                                id="fullName"
                                                className="form-control"
                                                name="fullName"
                                                onChange={(e) => onInputChange(e)}
                                                defaultValue={instructorData?.fullName}
                                                placeholder="Enter Full name"
                                            />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <label className="form-label" htmlFor="email">
                                                Email
                                            </label>
                                            <input
                                                type="text"
                                                id="email"
                                                className="form-control"
                                                name="email"
                                                onChange={(e) => onInputChange(e)}
                                                defaultValue={instructorData?.email}
                                                placeholder="Enter display name"
                                            />
                                        </Col>
                                        <Col md="6">
                                            {/* <FormGroup> */}
                                            <label className="form-label" htmlFor="gender">
                                            Gender
                                            </label>
                                            <input
                                            type="text"
                                            id="gender"
                                            className="form-control"
                                            name="gender"
                                            onChange={(e) => onInputChange(e)}
                                            defaultValue={instructorData?.gender}
                                            placeholder="Enter gender"
                                            />
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                            <label className="form-label" htmlFor="phone-no">
                                                Phone Number
                                            </label>
                                            <input
                                                type="number"
                                                id="phone-no"
                                                className="form-control"
                                                name="phone"
                                                onChange={(e) => onInputChange(e)}
                                                defaultValue={instructorData?.phoneNumber}
                                                placeholder="Phone Number"
                                            />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <label className="form-label" htmlFor="taxNumber">
                                                GST/HST Number
                                            </label>
                                            <input
                                                type="text"
                                                id="taxNumber"
                                                className="form-control"
                                                name="taxNumber"
                                                onChange={(e) => onInputChange(e)}
                                                defaultValue={instructorData?.taxNumber}
                                                placeholder="Enter GST/HST Number"
                                            />
                                        </Col>
                                        <Col md="6">
                                            {/* <FormGroup> */}
                                            <label className="form-label" htmlFor="baseRate">
                                                Hourly Rate
                                            </label>
                                            <input
                                                type="text"
                                                id="baseRate"
                                                className="form-control"
                                                name="baseRate"
                                                onChange={(e) => onInputChange(e)}
                                                defaultValue={instructorData?.baseRate}
                                                placeholder="Enter hourly Rate"
                                            />
                                        </Col>
                                        <Col md="12">
                                            {/* <FormGroup> */}
                                            <label className="form-label" htmlFor="bio">
                                            Bio
                                            </label>
                                            <input
                                            type="text"
                                            id="gender"
                                            className="form-control"
                                            name="bio"
                                            onChange={(e) => onInputChange(e)}
                                            defaultValue={instructorData?.bio}
                                            placeholder="Enter bio"
                                            />
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                            <label className="form-label" htmlFor="status">
                                                Status
                                            </label>
                                            <RSelect
                                                defaultValue={{ value: instructorData?.status, label: instructorData?.status }}
                                                options={statusOptions}
                                                placeholder="Select a status"
                                                onChange={(e) => setStatus(e.value)}
                                            />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                            <label className="form-label" htmlFor="status">
                                                Collects Sales Tax
                                            </label>
                                            <RSelect
                                                defaultValue={{ value: instructorData?.collectsTax ? "true" : !instructorData?.collectsTax ? "false" : "true", label: instructorData?.collectsTax ? "True" : !instructorData?.collectsTax ? "False" : "True" }}
                                                options={checkMark}
                                                placeholder=""
                                                onChange={(e) => setCollectSalestax(e.value)}
                                            />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                            <label className="form-label" htmlFor="status">
                                                Time Zone
                                            </label>
                                            <RSelect
                                                defaultValue={{ value: instructorData?.timeZoneOffset ? instructorData?.timeZoneOffset : 0, label: instructorData?.timeZoneOffset === 0 ? "EST" : instructorData?.timeZoneOffset === 1 ? "CST" : instructorData?.timeZoneOffset === 2 ? "MST" : instructorData?.timeZoneOffset === 3 ? "PST" : "EST" }}
                                                options={timeZoneOffsetOptions}
                                                placeholder=""
                                                onChange={(e) => { console.log('e.value', e.value) ; setTimeZoneOffset(e.value)}}
                                            />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                            <label className="form-label" htmlFor="state">
                                                State
                                            </label>
                                            <input
                                                type="text"
                                                id="state"
                                                className="form-control"
                                                name="state"
                                                onChange={(e) => onInputChange(e)}
                                                defaultValue={instructorData?.state}
                                                placeholder="State"
                                            />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <label className="form-label" htmlFor="baseRate">
                                                Rippling Employee Number
                                            </label>
                                            <input
                                                type="number"
                                                id="ripplingEmployeeNumber"
                                                className="form-control"
                                                name="ripplingEmployeeNumber"
                                                onChange={(e) => onInputChange(e)}
                                                defaultValue={instructorData?.ripplingEmployeeNumber}
                                                placeholder="Enter rippling employee number"
                                            />
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                            <label className="form-label" htmlFor="address">
                                                Address
                                            </label>
                                            <input
                                                type="text"
                                                id="address"
                                                className="form-control"
                                                name="address"
                                                onChange={(e) => onInputChange(e)}
                                                defaultValue={instructorData?.address}
                                                placeholder="Enter Address"
                                            />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                            <label className="form-label" htmlFor="address">
                                                Languages
                                            </label>
                                            <input
                                                type="text"
                                                id="address"
                                                className="form-control"
                                                name="address"
                                                onChange={(e) => setInputFieldData(e.target.value)}
                                                placeholder="Enter Language"
                                            />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2" className="d-flex justify-content-center align-items-center mt-4">
                                            <Button
                                            color="primary"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setLanguages([...languages, inputFieldData]);
                                            }}
                                            >
                                            <Icon name="plus"></Icon>
                                            </Button>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                            <label className="form-label" htmlFor="address">
                                                Service Areas
                                            </label>
                                            <input
                                                type="text"
                                                id="address"
                                                className="form-control"
                                                name="address"
                                                onChange={(e) => setInputFieldData(e.target.value)}
                                                placeholder="Enter Address"
                                            />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2" className="d-flex justify-content-center align-items-center mt-4">
                                            <Button
                                            color="primary"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setServiceAreas([...serviceAreas, inputFieldData]);
                                            }}
                                            >
                                            <Icon name="plus"></Icon>
                                            </Button>
                                        </Col>
                                        <Col md="6">
                                            {languages.map((language, index) => {
                                            return (
                                                <Col md="12" className='pl-0'>
                                                <FormGroup
                                                    style={{
                                                    backgroundColor: "#dbdfea",
                                                    borderRadius: "5px",
                                                    border: "10px solid #dbdfea",
                                                    }}
                                                    className="form-label mt-2 d-flex justify-content-between"
                                                >
                                                    {language}
                                                    <Button
                                                    color="danger"
                                                    size="sm"
                                                    className="ml-2 "
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        const fileteredLanguages = languages.filter((service, idx) => index !== idx);
                                                        setLanguages(fileteredLanguages);
                                                    }}
                                                    >
                                                    <Icon name="cross"></Icon>
                                                    </Button>
                                                </FormGroup>
                                                </Col>
                                            );
                                            })}
                                        </Col>
                                        <Col md="6">
                                            {serviceAreas.map((serviceArea, index) => {
                                            return (
                                                <Col md="12" className='pl-0'>
                                                <FormGroup
                                                    style={{
                                                    backgroundColor: "#dbdfea",
                                                    borderRadius: "5px",
                                                    border: "10px solid #dbdfea",
                                                    }}
                                                    className="form-label mt-2 ml-0 d-flex justify-content-between"
                                                >
                                                    {serviceArea}
                                                    <Button
                                                    color="danger"
                                                    size="sm"
                                                    className="ml-2"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        const fileteredArea = serviceAreas.filter((service, idx) => index !== idx);
                                                        setServiceAreas(fileteredArea);
                                                    }}
                                                    >
                                                    <Icon name="cross"></Icon>
                                                    </Button>
                                                </FormGroup>
                                                </Col>
                                            );
                                            })}
                                        </Col>
                                        <Col size="12">
                                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <Button
                                                        onClick={handleEditInstructorProfile}
                                                        disabled={UpdateInstructorProfileLoading}
                                                        color="primary"
                                                        size="lg"
                                                        type="submit"
                                                    >
                                                        {UpdateInstructorProfileLoading ? <Spinner /> : "Update Profile"}
                                                    </Button>
                                                </li>
                                                <li>
                                                    <div
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        setModal(false);
                                                    }}
                                                    className="link link-light cursor_pointer"
                                                    >
                                                        Cancel
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                        </Row>
                                    </form>
                                </Row>
                            </div>
                        </div>
                    )}
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default UpdateInstructorProfileModal;
