import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetInstructorReviewsThunk = createAsyncThunk(
  "GetInstructorReviews/GetInstructorReviewsThunk",
  async ({ instructorId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${ApiEndpoints.InstructorsEndPoint.GetInstructorReviews}?instructorId=${instructorId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data?.reviews;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const GetInstructorReviewsSlice = createSlice({
  name: "GetInstructorReviews",
  initialState: {
    instructorReviewsData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetInstructorReviewsData: (state, action) => {
      state.instructorReviewsData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [GetInstructorReviewsThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GetInstructorReviewsThunk.fulfilled]: (state, action) => {
      state.instructorReviewsData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [GetInstructorReviewsThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.error = true;
      state.loading = false;
    },
  },
});

export default GetInstructorReviewsSlice.reducer;

export const { ResetInstructorReviewsData } = GetInstructorReviewsSlice.actions;

export { GetInstructorReviewsThunk };
