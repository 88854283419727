import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Button, Card, Spinner } from "reactstrap";
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, PreviewAltCard } from "../../components/Component";
import { GetStudentNotificationThunk } from "../../redux/ConfigurationSlice/GetStudentNotificationSlice";
import { GetAllRegionsThunk } from "../../redux/ConfigurationSlice/GetAllRegionsSlice.js";
import NotificationModal from "./NotificationModal.js";
import AddRegionModal from "./AddRegionModal.js";

const Configuration = () => {
    const dispatch = useDispatch();

    const [notificationData, setNotificationData] = useState();
    const [modal, setModal] = useState({
        studentNotification: false,
        addRegion: false
    })

    const { loading: getAllRegionsLoading, getAllRegionsData } = useSelector((state) => state.configuration.getAllRegions)
    const { loading, getStudentNotificationData } = useSelector((state) => state.configuration.getStudentNotification)

    console.log('getAllRegionsData', getAllRegionsData)
    useEffect(() => {
        dispatch(GetStudentNotificationThunk());
        dispatch(GetAllRegionsThunk());
    }, [dispatch]);

    const handleNotificationModal = ({ field, value }) => {
        setNotificationData({ field, value });
        setModal({ studentNotification: true });
    }

    return (
        <React.Fragment>
            <Head title="Configuration - Admin portal"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Admin Configuration</BlockTitle>
                            <BlockDes className="text-soft">
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    {loading ?
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                            <Spinner />
                        </div>
                        : (
                            <>
                                <Card className="card-bordered card-stretch">
                                    <div className="card-inner-group">
                                        <div className="card-inner p-0">
                                            <table className="table table-tranx">
                                                <tbody>
                                                    <tr className="tb-tnx-item">
                                                        <td className="tb-tnx-info">
                                                            <div className="tb-tnx-desc">
                                                                <span className="title">Student Lesson Reminder Notification</span>
                                                            </div>
                                                        </td>
                                                        <td className="tb-tnx-action">
                                                            <span className={`ml-2 badge badge-dot `} >
                                                                <div className="custom-control custom-switch">
                                                                    <input type="checkbox" className="custom-control-input form-control" checked={getStudentNotificationData.twilioStudentNotificationsEnabled} onClick={() => handleNotificationModal({field: "twilioStudentNotificationsEnabled", value: !getStudentNotificationData.twilioStudentNotificationsEnabled})} id="twilioStudentNotificationsEnabled" />
                                                                    <label className="custom-control-label" htmlFor="twilioStudentNotificationsEnabled"> </label>
                                                                </div> 
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Card>
                                <Card className="card-bordered card-stretch">
                                    <div className="card-inner-group">
                                        <div className="card-inner p-0">
                                            <table className="table table-tranx">
                                                <tbody>
                                                    <tr className="tb-tnx-item">
                                                        <td className="tb-tnx-info">
                                                            <div className="tb-tnx-desc">
                                                                <span className="title">Instructor Reminder Notification</span>
                                                            </div>
                                                        </td>
                                                        <td className="tb-tnx-action">
                                                            <span className={`ml-2 badge badge-dot `} >
                                                                <div className="custom-control custom-switch">
                                                                    <input type="checkbox" className="custom-control-input form-control" checked={getStudentNotificationData.instructorNotificationsEnabled} onClick={() => handleNotificationModal({field: "instructorNotificationsEnabled", value: !getStudentNotificationData.instructorNotificationsEnabled})} id="instructorNotificationsEnabled" />
                                                                    <label className="custom-control-label" htmlFor="instructorNotificationsEnabled"> </label>
                                                                </div> 
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Card>
                            </>
                        )
                    }
                </Block>
                <Block className="mt-4">
                    <BlockHead size="sm">
                        <BlockBetween>
                        <BlockHeadContent>
                                <BlockTitle page>Regions</BlockTitle>
                                <BlockDes className="text-soft">
                                </BlockDes>
                            </BlockHeadContent>
                            <BlockHeadContent>
                            <ul className="nk-block-tools-opt">
                                <li>
                                    <Button color="primary" onClick={() => setModal({addRegion: true})}>
                                    <Icon name="plus" />
                                    <span>Add Region</span>
                                    </Button>
                                </li>
                            </ul>
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>

                    {getAllRegionsLoading ? 
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                            <Spinner />
                        </div>
                    : (
                        <div className="row">
                            {getAllRegionsData?.map((regionData, index)=> {
                                return (
                                    <div className="col-lg-4 mt-3">
                                        {console.log('regionData', regionData)}
                                            <PreviewAltCard>
                                            <div className="team">
                                                <div className="user-card user-card-s2">
                                                    <h6>{regionData?.region} </h6>
                                                </div>
                                                <ul className="team-statistics">
                                                    {regionData?.licenseTypes?.map((licencetype, index)=> {
                                                        return (
                                                            <li>
                                                                <span> <span style={{fontWeight: "bold"}}>{index + 1}. </span>  {licencetype?.label}</span>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                            </PreviewAltCard>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </Block>
            </Content>
            {modal.studentNotification && (
                <NotificationModal modal={modal} setModal={()=> setModal({studentNotification: false})} notificationData={notificationData} />
            )}
            {modal.addRegion && (
                <AddRegionModal modal={modal} setModal={()=> setModal({addRegion: false})} notificationData={notificationData} />
            )}
        </React.Fragment>
    )
}

export default Configuration;
