import React, { useEffect, useState } from "react";

import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap/es";
import { useParams } from "react-router";
import moment from "moment-timezone";
import { Toaster } from "react-hot-toast";

import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Icon, PreviewAltCard } from "../../../components/Component";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { GetInstructorLessonsThunk } from "../../../redux/InstructorSlice/GetInstructorLessons";
import { GetInstructorScheduleThunk } from "../../../redux/InstructorSlice/GetInstructorScheduleSlice";
import AddInstructorEventModal from "./AddInstructorEventModal";
import InstructorLessonCalender from "./InstructorLessonCalender";
import AddInstructorOutOfOfficeModal from "./AddInstructorOutOfOfficeModal";

const InstructorCalender = () => { 
  const { instructorId } = useParams();
  const dispatch = useDispatch();

  // ***************************************** All API's SELECTOR ********************************//
  const { loading, GetInstructorLessonsData } = useSelector((state) => state.instructors.getInstructorLessons);
  const { loading: GetInstructorScheduleLoading, instructorScheduleData } = useSelector((state) => state.instructors.getInstructorSchedule);

  console.log('GetInstructorLessonsData', GetInstructorLessonsData)
  // ***************************************** All Hooks ********************************//
  const [modal, setModal] = useState({
    addOutOfOffice: false,
    addEvent: false
  });

  const [mockEvents, updateEvent] = useState([]);

  useEffect(() => {
    dispatch(GetInstructorLessonsThunk({ instructorId }));
    dispatch(GetInstructorScheduleThunk({ instructorId }));
  }, [dispatch, instructorId]);

  useEffect(() => {
    // if (GetInstructorLessonsData) {
      var newArr = GetInstructorLessonsData?.scheduled?.filter((lesson, index) => {
        if (lesson.status !== "Awaiting Scheduling") return lesson;
      });

      newArr = newArr?.concat(GetInstructorLessonsData?.completed)
      newArr = newArr?.map((lesson) => {
        let colorName = "";
        let icon = null;
        if (lesson.status === "Scheduled") {
          colorName = "fc-event-info";
        } else if (lesson.status === "Completed") {
          colorName = "fc-event-success";
        }
        if(!lesson?.lessonSheetFilled && lesson?.status === "Completed") {
          icon = "⚠️"; 
        }

        return {
          ...lesson,
          id: "default-event-id-" + lesson._id,
          title: lesson?.studentName,
          start: moment.utc(lesson.dateTime).format(),
          end: moment(lesson.dateTime).utcOffset(true).add("hour", ((lesson?.duration / 60) - 1) ).format(),
          className: colorName,
          type: { value: colorName, label: lesson.status },
          icon: icon, // add icon based on status
        };
      });
      const eventArray = GetInstructorLessonsData?.events?.map((instructorEvent)=>{
        let colorName = "fc-event-orange";
        return {
          ...instructorEvent,
          id: "default-event-id-" + instructorEvent._id,
          title: instructorEvent?.eventName,
          start: moment.utc(instructorEvent.startTime).format(),
          end: moment(instructorEvent.endTime).utcOffset(0,false).format(),
          className: colorName,
          type: { value: colorName, label: instructorEvent.status },
        };
      });
      const tempSlotsArray = GetInstructorLessonsData?.tempSlots?.map((instructorTempSlots)=>{
        return {
          ...instructorTempSlots,
          id: "default-event-id-" + instructorTempSlots._id,
          start: moment.utc(instructorTempSlots.startTime).format(),
          end: moment(instructorTempSlots.endTime).utcOffset(0,false).format(),
          display: "background",
          color: "#FF5733",
        };
      });
      const outOfOfficeArray = GetInstructorLessonsData?.outOfOffice?.map((instructorOutOfOffice)=>{
        let colorName = "fc-event-danger";
        return {
          ...instructorOutOfOffice,
          id: "default-event-id-" + instructorOutOfOffice._id,
          title: "Out Of Office",
          start: moment.utc(instructorOutOfOffice.start).format(),
          end: moment.utc(instructorOutOfOffice.end).format(),
          className: colorName,

          type: { value: colorName, label: instructorOutOfOffice.status },
        };
      })

      newArr = newArr?.concat(eventArray);
      newArr = newArr?.concat(outOfOfficeArray);
      newArr = newArr?.concat(tempSlotsArray);

      updateEvent(newArr);
    // }
  }, [GetInstructorLessonsData]);

  return (
    <React.Fragment>
      <Toaster />
      {loading || GetInstructorScheduleLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <Spinner />
        </div>
      ) : (
        <>
          <Head title="Calender" />
            <Content>
              <BlockHead size="sm">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle page>Instructor Schedule</BlockTitle>
                  </BlockHeadContent>
                  <BlockHeadContent>
                    <Button color="primary" onClick={() => setModal({ addEvent: true })}>
                      <Icon name="plus" />
                      <span>Add Event</span>
                    </Button>
                  </BlockHeadContent>
                  <BlockHeadContent>
                    <Button color="primary" onClick={() => setModal({ addOutOfOffice: true })}>
                      <Icon name="plus" />
                      <span>Add Out of Office</span>
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <Block>
                <PreviewAltCard>
                  <InstructorLessonCalender
                    lessonsDetail={mockEvents}
                    businessHours={instructorScheduleData?.businessHours}
                  />
                </PreviewAltCard>
              </Block>
            </Content>
        </>
      )}
      {modal.addEvent && <AddInstructorEventModal modal={modal} setModal={ ()=> setModal({addEvent: false})} instructorId={instructorId}/> }
      {modal.addOutOfOffice && <AddInstructorOutOfOfficeModal modal={modal} setModal={ ()=> setModal({addOutOfOffice: false})} instructorId={instructorId}/> }
    </React.Fragment>
  );
};

export default InstructorCalender;
