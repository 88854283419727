import React, { useEffect, useState } from "react";
import { Modal, ModalBody, FormGroup, Spinner } from "reactstrap";
import {
  Icon,
  Row,
  Col,
  Button,
  RSelect,
} from "../../../../components/Component";
import DatePicker from "react-datepicker";

const AddInCarReports = ({modal, setModal}) => {
    const [formData, setFormData] = useState();


    const onInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    
    return (
        <React.Fragment>
            <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
                <ModalBody>
                    <div
                        onClick={(ev) => {
                        ev.preventDefault();
                        setModal(false);
                        }}
                        className="close cursor_pointer"
                    >
                        <Icon name="cross-sm" />
                    </div>
                    <div className="p-2">
                        <h5 className="title mb-5">Add In-Car Report</h5>
                        <div className="tab-content">
                        <div className={`tab-pane active`} id="personal">
                            <Row className="gy-4">
                                <Col md="6">
                                    <FormGroup>
                                    <label className="form-label" htmlFor="fullName">
                                        Full Name
                                    </label>
                                    <input
                                        type="text"
                                        id="fullName"
                                        className="form-control"
                                        name="fullName"
                                        onChange={(e) => onInputChange(e)}
                                        defaultValue={formData.fullName}
                                        placeholder="Enter Full name"
                                    />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                    <label className="form-label" htmlFor="drivingLicenseNumber">
                                        Driving License Number
                                    </label>
                                    <input
                                        type="text"
                                        id="drivingLicenseNumber"
                                        className="form-control"
                                        name="drivingLicenseNumber"
                                        onChange={(e) => onInputChange(e)}
                                        defaultValue={formData.drivingLicenseNumber}
                                        placeholder="X1234-12345-12345"
                                    />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                    <label className="form-label" htmlFor="phoneNumber">
                                        Phone Number
                                    </label>
                                    <input
                                        type="number"
                                        id="phoneNumber"
                                        className="form-control"
                                        name="phoneNumber"
                                        onChange={(e) => onInputChange(e)}
                                        defaultValue={formData.phoneNumber}
                                        placeholder="Phone Number"
                                    />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                    <label className="form-label">Start Date &amp; Time</label>
                                    <Row className="gx-2">
                                        <div className="w-55">
                                        <div className="form-control-wrap">
                                            <DatePicker
                                            selected={formData.date}
                                            onChange={(date) => setFormData({ ...formData, date: date })}
                                            className="form-control date-picker"
                                            />
                                        </div>
                                        </div>
                                        {/* <div className="w-45">
                                        <div className="form-control-wrap has-timepicker">
                                            <DatePicker
                                            selected={formData.time}
                                            onChange={(time) => setLessonDetail({ ...lessonDetail, time: time })}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={30}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            className="form-control date-picker"
                                            />
                                        </div>
                                        </div> */}
                                    </Row>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                    <label className="form-label">Student Type</label>
                                    <div className="form-control-wrap">
                                        <RSelect
                                        name="homePickup"
                                        options={[{value: true ,label: "True" }, {value: false ,label: "False" }]}
                                        defaultValue={{value: formData?.homePickup ,label: formData?.homePickup? "Home Pickup": "Central Pickup" }}
                                        onChange={(e) => {
                                            setFormData({ ...formData, homePickup: e.value });
                                        }}
                                        />
                                    </div>
                                    </FormGroup>
                                </Col>
                                <Col size="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    <li>
                                        <Button
                                        // disabled={EditStudentProfileLoading}
                                        color="primary"
                                        size="lg"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            // handleUpdateStudentProfile();
                                        }}
                                        >
                                            Add
                                        {/* {EditStudentProfileLoading ? <Spinner /> : "Update Profile"} */}
                                        </Button>
                                    </li>
                                    <li>
                                        <div
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModal(false);
                                            }}
                                            className="link link-light cursor_pointer"
                                        >
                                        Cancel
                                        </div>
                                    </li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>    
        </React.Fragment>
    )
};

export default AddInCarReports;