import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const UnAssignInstructorGeoFencingThunk = createAsyncThunk(
  "UnAssignInstructorGeoFencing/UnAssignInstructorGeoFencingThunk",
  async ({ instructorId,  geoFencingId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${ApiEndpoints.InstructorsEndPoint.UnassignInstructorGeoFencing}?instructorId=${instructorId}&geoFencingId=${geoFencingId}`,
        { },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong";
        return rejectWithValue(errorMessage);
    }
  }
);

const UnAssignInstructorGeoFencingSlice = createSlice({
  name: "UnAssignInstructorGeoFencing",
  initialState: {
    UnAssignInstructorGeoFencingData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetUnAssignInstructorGeoFencing: (state, action) => {
      state.UnAssignInstructorGeoFencingData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [UnAssignInstructorGeoFencingThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [UnAssignInstructorGeoFencingThunk.fulfilled]: (state, action) => {
      state.UnAssignInstructorGeoFencingData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [UnAssignInstructorGeoFencingThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.error = true;
      state.loading = false;
      state.UnAssignInstructorGeoFencingData = [];
    },
  },
});

export default UnAssignInstructorGeoFencingSlice.reducer;

export const { ResetUnAssignInstructorGeoFencing } = UnAssignInstructorGeoFencingSlice.actions;

export { UnAssignInstructorGeoFencingThunk };
