import React, { useState, useEffect } from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Card,
  Modal,
  ModalBody,
  DropdownItem,
  Spinner,
  ModalHeader,
  CardTitle,
  CardText,
  CardBody,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Component";
import { GetAllInstructorsThunk } from "../../../redux/InstructorSlice/GetAllInstructorSlice";
import { GetAllPickupPointsThunk } from "../../../redux/PickupPoints/GetAllPickupPoints";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { GetAllInstructorInvoicesThunk } from "../../../redux/InstructorSlice/GetAllInstructorInvoiceSlice";
import { GetInstructorInvoiceDetailThunk } from "../../../redux/InstructorSlice/GetInstructorInvoiceDetailSlice";
import ChangeInstructorInvoiceStatusModal from "./ChangeInstructorInvoiceStatusModal";

const InstructorInvoices = () => {
    const { instructorId } = useParams();
  const dispatch = useDispatch();

  const {  allInstructorsData } = useSelector(
    (state) => state.instructors.getAllInstructors
  );

  const { loading, allInstructorInvoicesData } = useSelector((
    (state)=> state.instructors.getAllInstructorInvoice
  ))

  const {
    loading: InvoideDetailLoading,
    instructorInvoiceDetailData
  } = useSelector((state)=> state.instructors.getInstructorInvoiceDetail)

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    view: false,
    add: false,
    delete: false,
    changeStatus: false
  });

  const [selectedInvoiceId, setSelecredInvoiceId] = useState('');
  const instructorsPerPage = 10;
  const [allInstructorsList, setAllInstructorsList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  // ********************************** Search and Pagination start ***********************************//
  const pagesVisited = pageNumber * instructorsPerPage - instructorsPerPage;

  const pageCount = Math.ceil(
    allInstructorsList.filter((instructorData) => {
      if (onSearchText === "") {
        return instructorData;
      } else if (
        instructorData.email.toLowerCase().includes(onSearchText.toLowerCase()) ||
        instructorData.fullName.toLowerCase().includes(onSearchText.toLowerCase()) ||
        instructorData.phoneNumber.toLowerCase().includes(onSearchText.toLowerCase()) ||
        instructorData.status.toLowerCase().includes(onSearchText.toLowerCase())
      ) {
        return instructorData;
      }
      return false;
    }).length / instructorsPerPage
  );

  const displayInstructorsList = allInstructorsList
    .filter((instructorData) => {
      if (onSearchText === "") {
        return instructorData;
      } else if (
        instructorData.email.toLowerCase().includes(onSearchText.toLowerCase()) ||
        instructorData.fullName.toLowerCase().includes(onSearchText.toLowerCase()) ||
        instructorData.phoneNumber.toLowerCase().includes(onSearchText.toLowerCase()) ||
        instructorData.status.toLowerCase().includes(onSearchText.toLowerCase())
      ) {
        return instructorData;
      }
      return false;
    })
    .slice(pagesVisited, pagesVisited + instructorsPerPage);

  // ********************************** Pagination End ***********************************//

  useEffect(() => {
    dispatch(GetAllInstructorsThunk());
    dispatch(GetAllPickupPointsThunk());
    dispatch(GetAllInstructorInvoicesThunk({instructorId: instructorId}));
  }, [dispatch]);

  useEffect(() => {
    setAllInstructorsList(allInstructorsData);
  }, [allInstructorsData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Change Page
  const paginate = (selected) => {
    setPageNumber(selected);
  };

  return (
    <React.Fragment>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "90vh" }}>
          <Spinner />
        </div>
      ) : (
        <>
          <Head title="Instructors"></Head>
          <Content>
            <BlockHead size="sm">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle page>Invoice</BlockTitle>
                  <BlockDes className="text-soft">
                    <p>You have total {allInstructorInvoicesData?.invoices?.previousInvoices?.length + 1} Invoices.</p>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  {/* <ul className="nk-block-tools-opt">
                    <li>
                      <Button color="primary" onClick={() => setModal({ add: true })}>
                        <Icon name="plus" />
                        <span>Add Invo</span>
                      </Button>
                    </li>
                  </ul> */}
                </BlockHeadContent>{" "}
              </BlockBetween>
            </BlockHead>

            <Block>

            { /* Current Invoices Card */}

              <Card className="card-bordered card-stretch">
                <div className="card-inner-group">
                  <div className="card-inner">
                    <div className="card-title-group">
                      <div className="card-title">
                        <h5 className="title">Current Invoice</h5>
                      </div>
                      <div className="card-tools mr-n1">
                        <ul className="btn-toolbar">
                          <li>
                            <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                              <Icon name="search"></Icon>
                            </Button>
                          </li>
                        </ul>
                      </div>
                      <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                        <div className="search-content">
                          <Button
                            className="search-back btn-icon toggle-search"
                            onClick={() => {
                              setSearchText("");
                              toggle();
                            }}
                          >
                            <Icon name="arrow-left"></Icon>
                          </Button>
                          <input
                            type="text"
                            className="form-control border-transparent form-focus-none"
                            placeholder="Search by Instructor name"
                            value={onSearchText}
                            onChange={(e) => onFilterChange(e)}
                          />
                          <Button className="search-submit btn-icon">
                            <Icon name="search"></Icon>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner p-0">
                    <table className="table table-tranx">
                      <thead>
                        <tr className="tb-tnx-head">
                          <th className="tb-tnx-info">
                            <span className="tb-tnx-desc d-none d-sm-inline-block">
                              <span>Invoice Number</span>
                            </span>
                            <span className="tb-tnx-date d-md-inline-block d-none">
                              <span className="d-none d-md-block">
                                <span>Date</span>
                                <span>Amount</span>
                              </span>
                            </span>
                          </th>
                          <th className="tb-tnx-amount is-alt">
                            <span className="tb-tnx-status d-none d-md-inline-block">Status</span>
                          </th>
                          <th className="tb-tnx-action">
                            <span>Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {allInstructorInvoicesData?.invoices?.currentInvoice && (
                          <tr key={1} className="tb-tnx-item">
                              <td className="tb-tnx-info">
                                  <div className="tb-tnx-desc">
                                    <span className="title">{allInstructorInvoicesData?.invoices?.currentInvoice?.invoiceNumber}</span>
                                  </div>
                                  <div className="tb-tnx-date">
                                    <span className="date">{moment.utc(allInstructorInvoicesData?.invoices?.currentInvoice?.endDate).subtract(1, "day").format("MMMM DD, yyyy")}</span>
                                    <span className="date">$ {allInstructorInvoicesData?.invoices?.currentInvoice?.invoiceTotal}</span>
                                  </div>
                              </td>
                              <td className="tb-tnx-amount is-alt">
                                  <div className="tb-tnx-status">
                                    <span
                                        className={`badge badge-dot badge-${allInstructorInvoicesData?.invoices?.currentInvoice?.status === "Paid"
                                        ? "success"
                                        : allInstructorInvoicesData?.invoices?.currentInvoice?.status === "Pending"
                                          && "danger"
                                        }`}
                                    >
                                        {allInstructorInvoicesData?.invoices?.currentInvoice?.status}
                                    </span>
                                  </div>
                              </td>
                              <td className="tb-tnx-action">
                                  <UncontrolledDropdown>
                                  <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                      <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                  <ul className="link-list-plain">
                                        <li>
                                          <DropdownItem onClick={(ev) => { ev.preventDefault(); setModal({ view: true }); dispatch(GetInstructorInvoiceDetailThunk({invoiceId: allInstructorInvoicesData?.invoices?.currentInvoice?._id})); }}>
                                            View Detail
                                          </DropdownItem>
                                        </li>
                                        <li>
                                          <DropdownItem onClick={(ev) => { ev.preventDefault(); setModal({ changeStatus: true }); setSelecredInvoiceId(allInstructorInvoicesData?.invoices?.currentInvoice?._id)}}>
                                            Mark as paid
                                          </DropdownItem>
                                        </li>
                                      </ul>
                                  </DropdownMenu>
                                  </UncontrolledDropdown>
                              </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Card>

              { /* Previous Invoices Card */}
              <Card className="card-bordered card-stretch mt-5">
                <div className="card-inner-group">
                  <div className="card-inner">
                    <div className="card-title-group">
                      <div className="card-title">
                        <h5 className="title">Previous Invoices</h5>
                      </div>
                      <div className="card-tools mr-n1">
                        <ul className="btn-toolbar">
                          <li>
                            <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                              <Icon name="search"></Icon>
                            </Button>
                          </li>
                        </ul>
                      </div>
                      <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                        <div className="search-content">
                          <Button
                            className="search-back btn-icon toggle-search"
                            onClick={() => {
                              setSearchText("");
                              toggle();
                            }}
                          >
                            <Icon name="arrow-left"></Icon>
                          </Button>
                          <input
                            type="text"
                            className="form-control border-transparent form-focus-none"
                            placeholder="Search by Instructor name"
                            value={onSearchText}
                            onChange={(e) => onFilterChange(e)}
                          />
                          <Button className="search-submit btn-icon">
                            <Icon name="search"></Icon>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner p-0">
                    <table className="table table-tranx">
                      <thead>
                        <tr className="tb-tnx-head">
                          <th className="tb-tnx-info">
                            <span className="tb-tnx-desc d-none d-sm-inline-block">
                              <span>Invoice Number</span>
                            </span>
                            <span className="tb-tnx-date d-md-inline-block d-none">
                              <span className="d-none d-md-block">
                                <span>Date</span>
                                <span>Amount</span>
                              </span>
                            </span>
                          </th>
                          <th className="tb-tnx-amount is-alt">
                            <span className="tb-tnx-status d-none d-md-inline-block">Status</span>
                          </th>
                          <th className="tb-tnx-action">
                            <span>Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {allInstructorInvoicesData?.invoices?.previousInvoices?.map((prevInvoice)=>{
                          return (
                            <tr key={1} className="tb-tnx-item">
                              <td className="tb-tnx-info">
                                  <div className="tb-tnx-desc">
                                  <span className="title">{prevInvoice.invoiceNumber}</span>
                                  </div>
                                  <div className="tb-tnx-date">
                                  <span className="date">{moment.utc(prevInvoice.endDate).subtract(1, "day").format("MMMM DD, yyyy")}</span>
                                  <span className="date">$ {prevInvoice.invoiceTotal}</span>
                                  </div>
                              </td>

                              <td className="tb-tnx-amount is-alt">
                                  <div className="tb-tnx-status">
                                  <span
                                      className={`badge badge-dot badge-${prevInvoice?.status === "Paid"
                                      ? "success"
                                      : prevInvoice?.status === "Pending"
                                        && "danger"
                                      }`}
                                  >
                                      {prevInvoice.status}
                                  </span>
                                  </div>
                              </td>
                              <td className="tb-tnx-action">
                                  <UncontrolledDropdown>
                                  <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                      <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                      <ul className="link-list-plain">
                                      <li>
                                      <DropdownItem onClick={(ev) => { ev.preventDefault(); setModal({ view: true }); dispatch(GetInstructorInvoiceDetailThunk({invoiceId: prevInvoice?._id})); }}>
                                          View Detail
                                          </DropdownItem>
                                      </li>
                                      <li>
                                          <DropdownItem onClick={(ev) => { ev.preventDefault(); setModal({ changeStatus: true }); setSelecredInvoiceId(prevInvoice?._id) }}>
                                          Mark as paid
                                          </DropdownItem>
                                      </li>
                                      </ul>
                                  </DropdownMenu>
                                  </UncontrolledDropdown>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="card-inner">
                    {allInstructorInvoicesData?.invoices?.previousInvoices ? (
                      <></>
                      // <PaginationComponent noDown itemPerPage={instructorsPerPage} totalItems={pageCount} paginate={paginate} currentPage={pageNumber} />
                    ) : (
                      <div className="text-center">
                        <span className="text-silent">No data found</span>
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </Block>

            {  /*    ***************** VIEW Invoices     */}

            <Modal
              isOpen={modal.view}
              toggle={() => setModal({ view: false })}
              className="modal-md"
            >
              <ModalHeader
                className="d-flex justify-content-between"
                tag="h3"
                toggle={() => setModal({ view: false })}
              >
                Invoice Summary
              </ModalHeader>
              <ModalBody>
                {instructorInvoiceDetailData?.invoice?.map((invoiceDetail, idx)=>{
                  return (
                    <React.Fragment>
                      <div className="d-flex justify-content-between">
                        <div>
                          <h6> Total : ${invoiceDetail?.invoiceTotal}</h6>
                          <span> <span style={{fontWeight: "600"}}> Invoice Period:</span>  {moment.utc(invoiceDetail?.startDate).format("MMMM DD")} - {moment.utc(invoiceDetail?.endDate).subtract(1, "day").format("MMMM DD")}</span>
                          <br/>
                          <span> <span style={{fontWeight: "600"}}>Payment Date:</span> { invoiceDetail?.paymentDate ? moment.utc(invoiceDetail?.paymentDate).format("DD MMM, YYYY") : "_"}</span>
                        </div>
                        <div>
                          <Button color="primary" size="md">Download Pdf </Button>
                        </div>
                      </div>
                      {invoiceDetail?.invoiceList?.map((invoiceData, index)=>{                  
                      return ( 
                        <Card className="card-bordered w-100 mb-1">
                          <CardBody className="card-inner">
                            <CardText tag="p" style={{ color: "rgb(92 106 124)", marginBottom: "0.2rem" }}>
                            <div className="d-flex justify-content-between">
                                <h6>{invoiceData?.studentName}</h6>
                                <span style={{color: "#37a2d0"}} >${invoiceData?.unitPrice *invoiceData?.quantity }</span>
                              </div>
                              <div className="d-flex justify-content-between mt-3">
                                <div>
                                  <span>Description</span>
                                  <br/>
                                  <span style={{color: 'black'}}>{invoiceData?.type}</span>
                                </div>
                                <div>
                                  <span >Qty</span>
                                  <br/>
                                  <span style={{color: 'black'}}>{invoiceData?.quantity}</span>
                                </div>
                                <div>
                                  <span >Unit Price</span>
                                  <br/>
                                  <span style={{color: 'black'}}>{invoiceData?.unitPrice}</span>
                                </div>
                              </div>
                            </CardText>
                            <CardTitle tag="h6"></CardTitle>
                          </CardBody>
                        </Card>
                      )
                      })}
                    </React.Fragment>
                  )
              })
                }
              </ModalBody>
            </Modal>
          </Content>
        </>
      )}

      {modal.changeStatus && <ChangeInstructorInvoiceStatusModal modal={modal} setModal={()=> setModal({changeStatus: false})} selectedInvoiceId={selectedInvoiceId} />}
    </React.Fragment>
  );
};

export default InstructorInvoices;
