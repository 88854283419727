import React, { useState } from "react";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button, Col, Form, Modal, ModalBody, Spinner } from "reactstrap";
import { Icon } from "../../../components/Component";
import { DeleteLessonThunk, ResetDeleteLessonData } from "../../../redux/LessonsSlice/DeleteStudentLessonSlice";
import { GetStudentRoadTestThunk } from "../../../redux/StudentsSlice/GetStudentRoadTestSlice";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const DeleteRoadTestModal = ({ modal, setModal, lessonId }) => {
    const dispatch = useDispatch();
    const { studentId } = useParams();
    const { loading, success, error, errorMessage } = useSelector((state) => state.lessons.deleteLesson);
    
    const [isRefund, setIsRefund] = useState(false);
    const [pages, setPages] = useState({
        page1: true,
        page2: false
    })

    const handleDeleteLessonReason = (value) => {
        setIsRefund(value);
        setPages({page1: false, page2: true});
    }

    const handleDeleteLesson = (e) => {
        e.preventDefault();
        dispatch(DeleteLessonThunk({ lessonId, isRefund }));
    };

    if (success) {
        toast.success("Lesson has been deleted successfully.");
        dispatch(ResetDeleteLessonData());
        dispatch(GetStudentRoadTestThunk({ studentId: studentId }));
        setModal({ showDeleteLesson: false });
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetDeleteLessonData());
        dispatch(GetStudentRoadTestThunk({ studentId: studentId }));
        setModal({ showDeleteLesson: false });
    }

    return (
        <React.Fragment>
            {pages.page1 && (
                <Modal
                    isOpen={modal.showDeleteLesson}
                    toggle={() => setModal({ showDeleteLesson: false })}
                    className="modal-dialog-centered"
                    size="md"
                >
                    <ModalBody>
                        <div
                            onClick={(ev) => {
                                ev.preventDefault();
                                setModal({ showDeleteLesson: false });
                            }}
                            className="close cursor_pointer"
                        >
                        <Icon name="cross-sm"></Icon>
                        </div>
                        <div className="p-2">
                        <h5 className="title">Delete Lesson</h5>
                        <div className="mt-4">
                            <Form className="row gy-4 mt-4">
                                <Col md="12"> 
                                    <span>
                                        Why would you like to delete this lesson?
                                    </span> 
                                </Col>
                                <Col md="6">
                                    <Button className='w-100 d-flex justify-content-center' color="primary" size="md" type="submit" onClick={(e)=> {e.preventDefault(); handleDeleteLessonReason(true)}}>
                                        Refund
                                    </Button>
                                </Col>
                                <Col md="6">
                                    <Button className='w-100 d-flex justify-content-center' color="primary" size="md" type="submit" onClick={(e)=> {e.preventDefault(); handleDeleteLessonReason(false)}}>
                                        Lesson added by mistake
                                    </Button>
                                </Col>
                            </Form>
                        </div>
                        </div>
                    </ModalBody>
                </Modal>
            )}

            {pages.page2 && (
                
                <Modal
                isOpen={modal.showDeleteLesson}
                toggle={() => setModal({ showDeleteLesson: false })}
                className="modal-dialog-centered"
                size="md"
                >
                    <ModalBody>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                        ev.preventDefault();

                        setModal({ showDeleteLesson: false });
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h4 className="title">Delete Lesson</h4>
                        <div className="mt-4">
                        <Form className="row gy-4 mt-4 mb-5">
                            <Col md="12">
                            <div>
                                <span>Are you sure you want to delete Lesson?</span>
                            </div>
                            </Col>
                            <Col size="12">
                            <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                <Button disabled={loading} onClick={(e) => handleDeleteLesson(e)} color="danger" size="md" type="submit">
                                    {loading ? <Spinner/> : "Delete Lesson"}
                                </Button>
                                </li>
                                <li>
                                <div
                                    onClick={(ev) => {
                                    ev.preventDefault();
                                    setModal({ showDeleteLesson: false });
                                    }}
                                    className="link link-light cursor_pointer"
                                >
                                    Cancel
                                </div>
                                </li>
                            </ul>
                            </Col>
                        </Form>
                        </div>
                    </div>
                    </ModalBody>
                </Modal>
            )}

        </React.Fragment>
    )
}

export default DeleteRoadTestModal;
