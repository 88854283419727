import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, DropdownItem, Spinner } from "reactstrap";

import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { Button, Block, Icon } from "../../../../components/Component";

const GraduatedStudents = ({ graduatedStudentsData }) => {
    const history = useHistory();


    const { loading, instructorStudentData } = useSelector(
        (state) => state.instructors.getInstructorStudent
    );

    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");


    // ********************************** Search and Pagination start ***********************************//

    const listingsPerPage = 5;
    const [allListing, setListing] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const pagesVisited = pageNumber * listingsPerPage;
    const pageCount = Math.ceil(
        allListing.filter((listing) => {
            if (onSearchText === "") {
                return listing;
            } else if (
                listing.fullName.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing.phoneNumber.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing.type.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return listing;
            }
            return false;
        }).length / listingsPerPage
    );

    const displayListing = allListing
        .filter((listing) => {
            if (onSearchText === "") {
                return listing;
            } else if (
                listing.fullName.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing.phoneNumber.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing.type.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return listing;
            }
            return false;
        })
        .slice(pagesVisited, pagesVisited + listingsPerPage);

    useEffect(() => {
        setListing(graduatedStudentsData);
    }, [graduatedStudentsData]);

    useEffect(() => {
        setPageNumber(0);
    }, [onSearchText]);

    // ********************************** Pagination End ***********************************//

    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };

    const toggle = () => setonSearch(!onSearch);

    const paginate = ({ selected }) => setPageNumber(selected);

    return (
        <React.Fragment>
            <Head title="Instructor's Student"></Head>
            {loading ? 
                <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                    <Spinner />
                </div>
            : (
                <Content>
                    <Block>
                        <Card className="card-bordered card-stretch">
                            <div className="card-inner-group">
                                <div className="card-inner">
                                    <div className="card-title-group">
                                        <div className="card-title">
                                            <h5 className="title">Graduated Students</h5>
                                        </div>
                                        <div className="card-tools mr-n1">
                                            <ul className="btn-toolbar">
                                                <li>
                                                    <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                                                    <Icon name="search"></Icon>
                                                    </Button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                                            <div className="search-content">
                                            <Button
                                                className="search-back btn-icon toggle-search"
                                                onClick={() => {
                                                setSearchText("");
                                                toggle();
                                                }}
                                            >
                                                <Icon name="arrow-left"></Icon>
                                            </Button>
                                            <input
                                                type="text"
                                                className="form-control border-transparent form-focus-none"
                                                placeholder="Search "
                                                value={onSearchText}
                                                onChange={(e) => onFilterChange(e)}
                                            />
                                            <Button className="search-submit btn-icon">
                                                <Icon name="search"></Icon>
                                            </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-inner p-0">
                                    <table className="table table-tranx">
                                        <thead>
                                            <tr className="tb-tnx-head">
                                                <th className="tb-tnx-id">
                                                    <span className="">#</span>
                                                </th>
                                                <th className="tb-tnx-info">
                                                    <span className="tb-tnx-desc d-none d-sm-inline-block">
                                                        <span>Name</span>
                                                    </span>
                                                    <span className="tb-tnx-date d-md-inline-block d-none">
                                                        <span className="d-md-none">Package</span>
                                                        <span className="d-none d-md-block">
                                                            <span>Phone</span>
                                                            <span>Lessons</span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th className="tb-tnx-action">
                                                    <span>Action</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayListing.length > 0
                                            ? displayListing?.map((studentDetail, index) => {
                                                return (
                                                <tr key={studentDetail.id} className="tb-tnx-item">
                                                    <td className="tb-tnx-id">
                                                    <a
                                                        href="#ref"
                                                        onClick={(ev) => {
                                                        ev.preventDefault();
                                                        }}
                                                    >
                                                        <span>{index + 1}</span>
                                                    </a>
                                                    </td>
                                                    <td className="tb-tnx-info">
                                                    <div className="tb-tnx-desc">
                                                        <span className="title">{studentDetail.fullName}</span>
                                                    </div>
                                                    <div className="tb-tnx-date">
                                                        <span className="date">{studentDetail.phoneNumber}</span>
                                                        <span className="date">{studentDetail.lessons}</span>
                                                    </div>
                                                    </td>
                                                    <td className="tb-tnx-action">
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle
                                                        tag="a"
                                                        className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                                        >
                                                        <Icon name="more-h"></Icon>
                                                        </DropdownToggle>
                                                        <DropdownMenu right>
                                                        <ul className="link-list-plain">
                                                            <li>
                                                            <DropdownItem
                                                                onClick={() => history.push(`/students/${studentDetail._id}`)}
                                                            >
                                                                View Details
                                                            </DropdownItem>
                                                            </li>
                                                            <li>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#print"
                                                                onClick={(ev) => {
                                                                ev.preventDefault();
                                                                }}
                                                            >
                                                                Delete Student
                                                            </DropdownItem>
                                                            </li>
                                                            <li>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#print"
                                                                onClick={(ev) => {
                                                                ev.preventDefault();
                                                                }}
                                                            >
                                                                Change Password
                                                            </DropdownItem>
                                                            </li>
                                                        </ul>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                    </td>
                                                </tr>
                                                );
                                            })
                                            :
                                            <div className="text-center">
                                                <span className="text-silent">No data found</span>
                                            </div>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-inner">
                                    <ReactPaginate
                                    breakLabel="..."
                                    breakClassName="pagination-btns"
                                    onPageChange={(number) => paginate(number)}
                                    // onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    pageCount={pageCount}
                                    previousLabel="<<  Prev "
                                    renderOnZeroPageCount={null}
                                    nextLabel="Next >>"
                                    previousClassName="pagination-next"
                                    nextClassName="pagination-next"
                                    activeClassName="pagination-active"
                                    pageClassName="pagination mt-0"
                                    pageLinkClassName="pagination-btns"
                                    containerClassName="pagination justify-content-start"
                                    />
                                </div>
                            </div>
                        </Card>
                    </Block>
                </Content>
            )}
        </React.Fragment>
    );
};

export default GraduatedStudents;
