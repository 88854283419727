import React, { useState } from 'react';

import { Button, Col, Form, FormGroup, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import DatePicker from "react-datepicker";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Icon } from '../../../components/Component';
import { AddStudentRoadTestThunk, ResetAddStudentRoadTestData } from '../../../redux/StudentsSlice/AddStudentRoadTestLessonSlice';
import toast from 'react-hot-toast';
import { ResetVerifyStripePIDData, VerifyStripePIDThunk } from '../../../redux/LessonsSlice/VerifyStripeIdSlice';
import { GetStudentRoadTestThunk } from '../../../redux/StudentsSlice/GetStudentRoadTestSlice';


const AddRoadTestModal = ({ modal, setModal, countryCode }) => {
    const { studentId } = useParams();
    const dispatch = useDispatch();

    const { 
        loading: AddStudentRoadTestLoading, 
        success: AddStudentRoadTestSuccess,
        error: AddStudentRoadTestError,
        errorMessage: AddStudentRoadTestErrorMessage
    } = useSelector((state) => state.Students.addStudentRoadTest);
    const {
        loading: verifyStripePidLoading,
        success: verifyStripePidSuccess, 
        error: verifyStripePidError,
        errorMessage: verifyStripePidErrorMessage
    } = useSelector((state)=> state.lessons.verifyStripePID)

    const [stripePID, setStripePID] = useState();
    const [isPaymentByInvoice, setIsPaymentByInvoice] = useState(false);
    const [pages, setPages] = useState({
        page1: true,
        page2: false,
        page3: false
    });

    const handleAddRoadTestLessonReason = (value) => {
        setIsPaymentByInvoice(value);
        value ? setPages({ page1: false, page2: true }) : setPages({ page1: false, page3: true }) ;
    }

    const handleVerifyStripePID = (params) => {
        dispatch(VerifyStripePIDThunk({ pid: stripePID, countryCode }))
    }

    if(verifyStripePidSuccess) {
        toast.success("Payment id has been verified successfully.");
        dispatch(ResetVerifyStripePIDData());
        setPages({page2: false, page3: true})
    }

    if(AddStudentRoadTestSuccess) {
        toast.success("Road test lesson has been added successfully.");
        dispatch(ResetAddStudentRoadTestData());
        dispatch(GetStudentRoadTestThunk({ studentId }));
        setModal();
    }

    if(verifyStripePidError) {
        toast.error(verifyStripePidErrorMessage);
        dispatch(ResetVerifyStripePIDData());
    }

    if(AddStudentRoadTestError) {
        toast.error(AddStudentRoadTestErrorMessage);
        dispatch(ResetAddStudentRoadTestData());
    }

    return (
        <React.Fragment>
            {pages.page1 && (
                <Modal
                    isOpen={modal.AddRoadTestLesson}
                    toggle={() => setModal({ AddRoadTestLesson: false })}
                    className="modal-dialog-centered"
                    size="md"
                >
                    <ModalBody>
                        <div
                            onClick={(ev) => {
                                ev.preventDefault();
                                setModal({ AddRoadTestLesson: false });
                            }}
                            className="close cursor_pointer"
                        >
                        <Icon name="cross-sm"></Icon>
                        </div>
                        <div className="p-2">
                        <h5 className="title">Add Road Test Lesson</h5>
                        <div className="mt-4">
                            <Form className="row gy-4 mt-4">
                                <Col md="6">
                                    <Button className='w-100 d-flex justify-content-center' color="primary" size="md" type="submit" onClick={(e)=> {e.preventDefault(); handleAddRoadTestLessonReason(true)}}>
                                        Payment made by invoice
                                    </Button>
                                </Col>
                                <Col md="6">
                                    <Button className='w-100 d-flex justify-content-center' color="primary" size="md" type="submit" onClick={(e)=> {e.preventDefault(); handleAddRoadTestLessonReason(false)}}>
                                        Compensatory lesson
                                    </Button>
                                </Col>
                            </Form>
                        </div>
                        </div>
                    </ModalBody>
                </Modal>
            )}

            {pages.page2 && (
                <Modal
                    isOpen={modal.AddRoadTestLesson}
                    toggle={() => setModal({ AddRoadTestLesson: false })}
                    className="modal-dialog-centered"
                    size="md"
                >
                    <ModalBody>
                        <div
                        onClick={(ev) => {
                            ev.preventDefault();
                            setModal({ AddRoadTestLesson: false });
                        }}
                        className="close cursor_pointer"
                        >
                        <Icon name="cross-sm"></Icon>
                        </div>
                        <div className="p-2">
                        <h5 className="title">Add Road Test Lesson</h5>
                        <div className="mt-4">
                            <Form className="row gy-4 mt-4">
                                <Col md="12">
                                    <FormGroup>
                                    <label className="form-label" htmlFor="phoneNumber">
                                        Stripe PID
                                    </label>
                                    <input
                                        type="text"
                                        id="stripePID"
                                        className="form-control"
                                        value={stripePID}
                                        name="stripePID"
                                        onChange={(e) => setStripePID(e.target.value)}
                                        defaultValue={stripePID}
                                        placeholder="Enter your Payment ID"
                                    />
                                    </FormGroup>
                                </Col>

                                <Col size="12">
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    <li>
                                        <Button color="primary" size="md" type="submit" disabled={verifyStripePidLoading} onClick={(e)=> handleVerifyStripePID(e)}>
                                            {verifyStripePidLoading ? <Spinner /> : "Next"}
                                        </Button>
                                    </li>
                                </ul>
                                </Col>
                            </Form>
                        </div>
                        </div>
                    </ModalBody>
                </Modal>
            )}

            {pages.page3 && (
                <Modal
                    isOpen={modal.AddRoadTestLesson}
                    toggle={() => setModal({ AddRoadTestLesson: false })}
                    className="modal-md"
                >
                    <ModalHeader toggle={() => setModal({ AddRoadTestLesson: false })}>Add Road Test Lesson</ModalHeader>
                    <ModalBody>
                    <form className="form-validate is-alter">
                        <Row className="gx-4 gy-3">
                        <Col md="12">
                            <p>
                                Are you sure you want to add a road test lesson?
                            </p>
                        </Col>
                        <Col md="12">
                            <ul className="d-flex justify-content-between gx-4 mt-1">
                            <li>
                                <Button
                                color="success"
                                className="btn-dim"
                                disabled={AddStudentRoadTestLoading}
                                onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(AddStudentRoadTestThunk({ studentId, stripePID }));
                                }}
                                >
                                {AddStudentRoadTestLoading ? <Spinner /> : "Add Road Test"}
                                </Button>
                            </li>
                            <li>
                                <Button
                                className="btn-dim"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setModal({ AddRoadTestLesson: false });
                                }}
                                >
                                Cancel
                                </Button>
                            </li>
                            </ul>
                        </Col>
                        </Row>
                    </form>
                    </ModalBody>
                </Modal>
        )}

        </React.Fragment>
    )
}

export default AddRoadTestModal;
