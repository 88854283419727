import React, { useCallback, useEffect, useRef, useState } from 'react'

import { GoogleMap, DrawingManager,  Polygon, useLoadScript } from '@react-google-maps/api'
import { Button, Spinner } from 'reactstrap';

const containerStyle = {
    width: "100%",
    height: "80vh",
};

export default function GeoMaps({ paths= [], point=[] }) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ['drawing'],
    });

    const [path, setPath] = useState([]);
    const [map, setMap] = useState();
    const [mapCenter, setMapCenter] = useState(null);
    const [resetKey, setResetKey] = useState(0); // Add a resetKey state
    const [state, setState] = useState({
        drawingMode: 'polygon'
    })

    const options = {
        drawingControl: true,
        drawingControlOptions: {
            drawingMode: ['Polygon']
        },
        polygonOptions: {
            fillColor: '#2196F3',
            strokeColor: '#2196F3',
            fillOpacity: 0.5,
            strokeWeight: 2,
            clickable: true,
            editable: true,
            draggable: true,
            zindex: 1
        }
    }

    const onPolygonComplete = React.useCallback(
        function onPolygonComplete(poly) {
            const polyArray = poly.getPath().getArray();
            let paths = [];
            polyArray.forEach(function (path) {
                paths.push({ lat: path.lat(), lng: path.lng() });
            })
            setPath(paths);
            point(paths);
            poly.setMap(null);
        },
        [point]
    )

    //define refs for Polygon instance and listeners
    const polygonRef = useRef(null);
    const listenerRef = useRef([]);
    const previousPathRef = useRef(null);

    //call setpath with new edited paths
    const onEdit = useCallback(() => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current.getPath().getArray().map(latlng => {
                return { lat: latlng.lat(), lng: latlng.lng() };
            })
            setPath(nextPath);
            point(nextPath);

            // const previousPath = previousPathRef.current;

            // if (previousPath) {
            //   // Find the latest changed coordinates
            //   const latestChangedCoordinates = nextPath.filter((coordinate, index) => {
            //     return (
            //       !previousPath[index] || // Newly added coordinate
            //       coordinate.lat !== previousPath[index].lat || // Changed latitude
            //       coordinate.lng !== previousPath[index].lng // Changed longitude
            //     );
            //   });
        
            // }
        
            // previousPathRef.current = nextPath;
        }
    }, [setPath, point, map]);

    const onLoad = useCallback(
        polygon => {
            polygonRef.current = polygon;
            const path = polygon.getPath()
            listenerRef.current.push(
                path.addListener("set_at", onEdit),
                path.addListener("insert_at", onEdit),
                path.addListener("remove_at", onEdit),
            )
        }, [onEdit]
    )

    const onUnmount = useCallback(() => {
        listenerRef.current.forEach(lis => lis.remove())
        polygonRef.current = null;
    }, []);

    const handleLoad = (map) => {
        setMap(map);
        if (!mapCenter) {
            setMapCenter(map.getCenter());
        }
    };

    const onResetMap = (second) => { 
        setPath([]);
        point([]);
        // setMapCenter(null);
        setResetKey((prevKey) => prevKey + 1); // Update the resetKey state to trigger re-render
        if (polygonRef.current) {
            const newPath = [];
            polygonRef.current.setPath(newPath);
        }
    }

    useEffect(() => {
        if (isLoaded && paths.length !== path.length) {
          setPath(paths);
        }
      }, [isLoaded, paths, path.length]);
    

    if (loadError) return console.log('Error loading maps');

    return (
        <div className='App'>
            {
                !isLoaded ?
                    <Spinner /> :
                    <>
                    <Button onClick={onResetMap}>
                        RESET Map
                    </Button>
                    <GoogleMap key={resetKey} mapContainerStyle={containerStyle} center={mapCenter ? mapCenter : { lat: 43.64254089782881, lng: -79.38701253368136 }} zoom={12} onLoad={handleLoad} >
                        {
                            // map &&
                            (path.length === 0 || path.length === 1 || path.length === 2
                                ?
                                (<DrawingManager
                                    drawingControl={true}
                                    drawingMode={['polygon']}
                                    options={options}
                                    editable
                                    draggable
                                    onPolygonComplete={onPolygonComplete}
                                    onMouseUp={onEdit}
                                    onDragEnd={onEdit}

                                />)
                                :
                                (
                                    <Polygon
                                        options={{
                                            fillColor: '#2196F3',
                                            strokeColor: '#2196F3',
                                            fillOpacity: 0.5,
                                            strokeWeight: 2
                                        }}
                                        editable
                                        draggable
                                        path={path}
                                        onLoad={onLoad}
                                        onUnmount={onUnmount}
                                        onMouseUp={onEdit}
                                        onDragEnd={onEdit}
                                    />
                                ))
                        }
                    </GoogleMap>
                </>
            }
        </div>
    )
}
