import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../../utils/ApiEndpoints";

// Remove In-Car Sheet 
const AddMultiLessonInCarSheetThunk = createAsyncThunk(
    "AddMultiLessonInCarSheet/AddMultiLessonInCarSheetThunk",
    async ({ studentId, roadTestTopics, maneuverTopics, maneuverNotes, roadTestNotes, simulatedRoadTest, overallGrade, studentSign, instructorSign, lessonId }, { rejectWithValue }) => {
        try {
        const response = await axios.post(`${ApiEndpoints.StudentsEndPoint.inCarSheet.AddMultiLessonInCarSheet}?studentId=${studentId}&lessonId=${lessonId}`, 
        {
            maneuverTopics, roadTestTopics, maneuverNotes, roadTestNotes, simulatedRoadTest, overallGrade, studentSign, instructorSign
        },
        {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        });
        return response.data;
        } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
        }
    }
);

const AddMultiLessonInCarSheetsSlice = createSlice({
    name: "AddMultiLessonInCarSheets",
    initialState: {
        AddMultiLessonInCarSheetData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAddMultiLessonInCarSheetData: (state, action) => {
        state.AddMultiLessonInCarSheetData = [];
        state.success = false;
        state.error = false;
        state.loading = false;
        state.errorMessage = "";
        },
    },
    extraReducers: {
        [AddMultiLessonInCarSheetThunk.pending]: (state, action) => {
        state.loading = true;
        state.success = false;
        state.error = false;
        },
        [AddMultiLessonInCarSheetThunk.fulfilled]: (state, action) => {
        state.AddMultiLessonInCarSheetData = [...state.AddMultiLessonInCarSheetData, action.payload.data];
        state.success = true;
        state.loading = false;
        },
        [AddMultiLessonInCarSheetThunk.rejected]: (state, action) => {
        state.errorMessage = action.payload.message;
        state.error = true;
        state.loading = false;
        },
    },
});

export default AddMultiLessonInCarSheetsSlice.reducer;

export const { ResetAddMultiLessonInCarSheetData } = AddMultiLessonInCarSheetsSlice.actions;

export { AddMultiLessonInCarSheetThunk };
