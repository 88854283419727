import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// Get InComplete InCarSheet
const GetInCompleteInCarSheetThunk = createAsyncThunk(
    "InCompleteInCarSheet/GetInCompleteInCarSheetThunk",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(ApiEndpoints.InCompleteInCarSheet.GetInCompleteInCarSheet, {
                headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
            const errorMessage = error?.response?.data?.message || "Something went wrong";
            return rejectWithValue(errorMessage);
        }
    }
);

const GetInCompleteInCarSheetSlice = createSlice({
    name: "InCompleteInCarSheet",
    initialState: {
        inCompleteInCarSheetData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetInCompleteInCarSheetData: (state, action) => {
            state.inCompleteInCarSheetData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [GetInCompleteInCarSheetThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [GetInCompleteInCarSheetThunk.fulfilled]: (state, action) => {
            state.inCompleteInCarSheetData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [GetInCompleteInCarSheetThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.inCompleteInCarSheetData = [];
        },
    },
});

export default GetInCompleteInCarSheetSlice.reducer;

export const { ResetInCompleteInCarSheetData } = GetInCompleteInCarSheetSlice.actions;

export { GetInCompleteInCarSheetThunk };
