import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const ConvertRoadTestToLessonThunk = createAsyncThunk(
"ConvertRoadTestToLesson/ConvertRoadTestToLessonThunk",
async ({ studentId, instructorId, lessons, packageName, expiryDate, roadTestId, homePickup }, { rejectWithValue }) => {
    try {
    const response = await axios.post(
        `${ApiEndpoints.LesssonsEndPoint.ConvertRoadTestToLesson}?studentId=${studentId}&instructorId=${instructorId}&roadTestId=${roadTestId}`,
        { lessons, packageName, expiryDate, isComplementary: false, homePickup },
        {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
    );
    return response.data;
    } catch (error) {
    console.log("error", error);
    return rejectWithValue(error.response.data);
    }
}
);

const ConvertRoadTestToLessonSlice = createSlice({
  name: "ConvertRoadTestToLesson",
  initialState: {
    roadTestToLessonData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetConvertRoadTestToLessonData: (state, action) => {
      state.roadTestToLessonData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [ConvertRoadTestToLessonThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [ConvertRoadTestToLessonThunk.fulfilled]: (state, action) => {
      state.roadTestToLessonData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [ConvertRoadTestToLessonThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default ConvertRoadTestToLessonSlice.reducer;

export const { ResetConvertRoadTestToLessonData } = ConvertRoadTestToLessonSlice.actions;

export { ConvertRoadTestToLessonThunk };
