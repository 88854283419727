import React, { useState } from "react";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, DropdownItem, Spinner } from "reactstrap";
import { Icon, PaginationComponent } from "../../../components/Component";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  ResetUpdateReferralPromotionStatusData,
  UpdateReferralPromotionStatusThunk,
} from "../../../redux/Promotions/UpdateReferralPromotionStatusSlice";
import toast from "react-hot-toast";
import { GetAllPromotionsThunk } from "../../../redux/Promotions/GetAllPromotionsSlice";

const RefferalPromotionTable = ({ refferalPromotionData }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);

  const { loading, success, error, errorMessage } = useSelector(
    (state) => state.promotions.updateReferralPromotionStatus
  );

  if (success) {
    toast.success("CashBack status has been updated successfully.");
    dispatch(ResetUpdateReferralPromotionStatusData());
    dispatch(GetAllPromotionsThunk());
  }

  if (error) {
    toast.error(errorMessage);
    dispatch(ResetUpdateReferralPromotionStatusData());
    dispatch(GetAllPromotionsThunk());
  }

  return (
    <React.Fragment>
      <Card className="card-bordered card-stretch">
        {loading ? (
          <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "40vh" }}>
            <Spinner />
          </div>
        ) : (
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="card-title-group">
                <div className="card-title">
                  <h5 className="title">Referral Promotions</h5>
                </div>
              </div>
            </div>
            <div className="card-inner p-0">
              <table className="table table-tranx">
                <thead>
                  <tr className="tb-tnx-head">
                    <th className="tb-tnx-info">
                      <span className="tb-tnx-desc d-none d-sm-inline-block">
                        <span>Referree </span>
                      </span>
                      <span className="tb-tnx-date d-md-inline-block d-none">
                        <span className="d-md-none"></span>
                        <span className="d-none d-md-block">
                          <span>Referred</span>
                          <span>Cash Back</span>
                        </span>
                      </span>
                    </th>
                    <th className="tb-tnx-id">
                      <span className="">Promo code</span>
                    </th>
                    <th className="tb-tnx-amount is-alt">
                      <span className="tb-tnx-status d-none d-md-inline-block">Signed Up</span>
                      <span className="tb-tnx-total">Cashback sent</span>
                    </th>
                    <th className="tb-tnx-action">
                      <span>Action</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {refferalPromotionData?.map((refferalPromotion, index) => {
                    return (
                      <>
                        <tr key={1} className="tb-tnx-item">
                          <td className="tb-tnx-info">
                            <div className="tb-tnx-desc">
                              <span className="title">
                                {refferalPromotion.name}
                                {/* - {refferalPromotion.email} */}
                              </span>
                            </div>
                            <div className="tb-tnx-date">
                              <span className="date">-</span>
                              <span className="date pl-2">{refferalPromotion.cashBackReceived}</span>
                            </div>
                          </td>
                          <td className=" pl-2 tb-tnx-id">
                            <a>
                              <span>-</span>
                            </a>
                          </td>
                          <td className="tb-tnx-amount is-alt">
                            <div className="tb-tnx-status">
                              <span>-</span>
                            </div>
                            <div className="tb-tnx-total">
                              <span>-</span>
                            </div>
                          </td>
                          <td className="tb-tnx-action"></td>
                        </tr>

                        {refferalPromotion?.referred.map((refferedUser, idx) => {
                          return (
                            <tr key={1} className="tb-tnx-item">
                              <td className="tb-tnx-info">
                                <div className="tb-tnx-desc">
                                  <span className="title">-</span>
                                </div>
                                <div className="tb-tnx-date">
                                  <span className="date">{refferedUser.email}</span>
                                  <span className="date pl-2">-</span>
                                </div>
                              </td>
                              <td className=" pl-2 tb-tnx-id">
                                <a>
                                  <span>{refferedUser.promoCode}</span>
                                </a>
                              </td>
                              <td className="tb-tnx-amount is-alt">
                                <div className="tb-tnx-status">
                                  <span
                                    className={`badge badge-dot badge-${!refferedUser.signedUp
                                      ? "success"
                                      : !refferedUser.signedUp === false && "danger"
                                      }`}
                                  >
                                    {!refferedUser.signedUp ? "Yes" : "No"}
                                  </span>
                                </div>
                                <div className="tb-tnx-total">
                                  <span
                                    className={`badge badge-dot badge-${refferedUser.cashBackSent === true
                                      ? "success"
                                      : refferedUser.cashBackSent === false && "danger"
                                      }`}
                                  >
                                    {refferedUser.cashBackSent ? "Yes" : "No"}
                                  </span>
                                </div>
                              </td>
                              <td className="tb-tnx-action">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <ul className="link-list-plain">
                                      <li>
                                        <DropdownItem
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            dispatch(
                                              UpdateReferralPromotionStatusThunk({ promoId: refferedUser?.promoId, isLMS: false })
                                            );
                                          }}
                                        >
                                          Update CashBack Status
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Card>
    </React.Fragment>
  );
};

export default RefferalPromotionTable;
