import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const AddInstructorScheduleThunk = createAsyncThunk(
  "AddInstructorSchedule/AddInstructorScheduleThunk",
  async ({ instructorId, newStartTime, newEndTime, locations, day, weekly }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${ApiEndpoints.InstructorsEndPoint.AddInstructorSchedule}?instructorId=${instructorId}`,
        {
          newStartTime,
          newEndTime,
          locations,
          day,
          weekly,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const AddInstructorScheduleSlice = createSlice({
  name: "AddInstructorSchedule",
  initialState: {
    AdddInstructorScheduleData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetAdddInstructorScheduleData: (state, action) => {
      state.AdddInstructorScheduleData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [AddInstructorScheduleThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [AddInstructorScheduleThunk.fulfilled]: (state, action) => {
      state.AdddInstructorScheduleData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [AddInstructorScheduleThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.error = true;
      state.loading = false;
    },
  },
});

export default AddInstructorScheduleSlice.reducer;

export const { ResetAdddInstructorScheduleData } = AddInstructorScheduleSlice.actions;

export { AddInstructorScheduleThunk };
