import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetAllInstructorsThunk = createAsyncThunk(
  "GetAllInstructors/GetAllInstructorsThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(ApiEndpoints.InstructorsEndPoint.GetAllInstructors, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
      return response.data.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const GetAllInstructorsSlice = createSlice({
  name: "GetAllInstructors",
  initialState: {
    allInstructorsData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetAllInstructorsData: (state, action) => {
      state.allInstructorsData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [GetAllInstructorsThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GetAllInstructorsThunk.fulfilled]: (state, action) => {
      state.allInstructorsData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [GetAllInstructorsThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.error = true;
      state.loading = false;
    },
  },
});

export default GetAllInstructorsSlice.reducer;

export const { ResetAllInstructorsData } = GetAllInstructorsSlice.actions;

export { GetAllInstructorsThunk };
