import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// add student
const ChangeStudentPasswordThunk = createAsyncThunk(
  "ChangeStudentPassword/ChangeStudentPasswordThunk",
  async ({ password, studentId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${ApiEndpoints.StudentsEndPoint.ChangeStudentPassword}?studentId=${studentId}`,
        { password },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const ChangeStudentPasswordsSlice = createSlice({
  name: "ChangeStudentPasswords",
  initialState: {
    ChangeStudentPasswordData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetChangeStudentPasswordData: (state, action) => {
      state.ChangeStudentPasswordData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [ChangeStudentPasswordThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [ChangeStudentPasswordThunk.fulfilled]: (state, action) => {
      state.ChangeStudentPasswordData = [...state.ChangeStudentPasswordData, action.payload.data];
      state.success = true;
      state.loading = false;
    },
    [ChangeStudentPasswordThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default ChangeStudentPasswordsSlice.reducer;

export const { ResetChangeStudentPasswordData } = ChangeStudentPasswordsSlice.actions;

export { ChangeStudentPasswordThunk };
