import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// Update Geo Fencing
const UpdateGeoFencingThunk = createAsyncThunk(
    "UpdateGeoFencing/UpdateGeoFencingThunk",
    async ({name, geoFencingPoints, _id}, { rejectWithValue }) => {
        try {
            const response = await axios.post(ApiEndpoints.GeoFencing.UpdateGeoFencing,
                {name, geoFencingPoints, _id},
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                });
            return response.data;
        } catch (error) {
            console.log("error", error);
            const errorMessage = error?.response?.data?.message || "Something went wrong";
            return rejectWithValue(errorMessage);
        }
    }
);

const UpdateGeoFencingSlice = createSlice({
    name: "UpdateGeoFencing",
    initialState: {
        updateGeoFencingData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetUpdateGeoFencingData: (state, action) => {
            state.updateGeoFencingData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [UpdateGeoFencingThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [UpdateGeoFencingThunk.fulfilled]: (state, action) => {
            state.updateGeoFencingData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [UpdateGeoFencingThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.updateGeoFencingData = [];
        },
    },
});

export default UpdateGeoFencingSlice.reducer;

export const { ResetUpdateGeoFencingData } = UpdateGeoFencingSlice.actions;

export { UpdateGeoFencingThunk };
