import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../../utils/ApiEndpoints";

// Remove In-Car Sheet
const GetAllRoadTestManeuversThunk = createAsyncThunk(
    "GetAllRoadTestManeuvers/GetAllRoadTestManeuversThunk",
    async ({ studentId, isRoadTest }, { rejectWithValue }) => {
        try {
        const response = await axios.get(`${ApiEndpoints.StudentsEndPoint.inCarSheet.GetAllInCarSheetManeuvers}?studentId=${studentId}&isRoadTest=${isRoadTest}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        });
        return response.data;
        } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
        }
    }
);

const GetAllRoadTestManeuversSlice = createSlice({
    name: "GetAllRoadTestManeuverss",
    initialState: {
        GetAllRoadTestManeuversData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetGetAllRoadTestManeuversData: (state, action) => {
        state.GetAllRoadTestManeuversData = {};
        state.success = false;
        state.error = false;
        state.loading = false;
        state.errorMessage = "";
        },
    },
    extraReducers: {
        [GetAllRoadTestManeuversThunk.pending]: (state, action) => {
        state.loading = true;
        state.success = false;
        state.error = false;
        },
        [GetAllRoadTestManeuversThunk.fulfilled]: (state, action) => {
        state.GetAllRoadTestManeuversData = action.payload;
        state.success = true;
        state.loading = false;
        },
        [GetAllRoadTestManeuversThunk.rejected]: (state, action) => {
        state.errorMessage = action.payload.message;
        state.error = true;
        state.loading = false;
        },
    },
});

export default GetAllRoadTestManeuversSlice.reducer;

export const { ResetGetAllRoadTestManeuversData } = GetAllRoadTestManeuversSlice.actions;

export { GetAllRoadTestManeuversThunk };
