import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetAllStudentsThunk = createAsyncThunk("GetAllStudent/GetAllStudentsThunk", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(ApiEndpoints.StudentsEndPoint.GetAllStudents, {
      headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
    });
    return response.data;
  } catch (error) {
    console.log("error", error);
    return rejectWithValue(error.response.data);
  }
});

// add student
const AddStudentThunk = createAsyncThunk(
  "GetAllStudent/AddStudentThunk",
  async ({ fullName, email, phoneNumber, password, postalCode, instructorId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        ApiEndpoints.StudentsEndPoint.AddStudent,
        { fullName, email, phoneNumber, password, postalCode, instructorId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const GetAllStudentsSlice = createSlice({
  name: "GetAllStudents",
  initialState: {
    allStudentData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetAllStudentData: (state, action) => {
      state.allStudentData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
    ResetAllStudentLoader: (state, action) => {
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [GetAllStudentsThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GetAllStudentsThunk.fulfilled]: (state, action) => {
      state.allStudentData = action.payload.data;
      state.success = true;
      state.loading = false;
    },
    [GetAllStudentsThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },

    [AddStudentThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [AddStudentThunk.fulfilled]: (state, action) => {
      state.allStudentData = [action.payload.data, ...state.allStudentData];
      state.success = true;
      state.loading = false;
    },
    [AddStudentThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default GetAllStudentsSlice.reducer;

export const { ResetAllStudentData, ResetAllStudentLoader } = GetAllStudentsSlice.actions;

export { GetAllStudentsThunk, AddStudentThunk };
