import React, { useState } from 'react';
import { Button, Col, Form, FormGroup, Modal, ModalBody, Spinner } from 'reactstrap';
import { Icon, RSelect } from '../../../components/Component';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { ChangeLessonInstructorThunk, ResetChangeLessonInstructorData } from '../../../redux/LessonsSlice/ChangeLessonInstructorSlice';
import { GetStudentLessonsThunk } from '../../../redux/StudentsSlice/GetStudentLessonsSlice';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const ChangeInstructorModal = ({ modal, setModal, lessonId, instructorList }) => {
    const { studentId } = useParams();
    const dispatch = useDispatch();
    const { loading, success, error, errorMessage } = useSelector((state)=> state.lessons.changeLessonInstructor)

    const [selectedInstructor, setSelectedInstructor] = useState();

    const handleChangeLessonInstructor = (e) => {
        e.preventDefault();
        dispatch(ChangeLessonInstructorThunk({ lessonId, instructorId: selectedInstructor }))
    }

    if(success){
        toast.success("Lesson instructor has been changed successfullly.");
        dispatch(ResetChangeLessonInstructorData());
        dispatch(GetStudentLessonsThunk({ studentId }));
        setModal();
    }

    if(error) {
        toast.error(errorMessage);
        dispatch(ResetChangeLessonInstructorData());
    }
    
    return (
        <React.Fragment>
            <Modal
                isOpen={modal.changeInstructor}
                toggle={() => setModal({ changeInstructor: false })}
                className="modal-dialog-centered"
                size="md"
                >
                <ModalBody>
                    <div onClick={(ev) => { ev.preventDefault(); setModal({ changeInstructor: false });}} className="close cursor_pointer" >
                    <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h4 className="title">Change lesson Instructor</h4>
                        <div className="mt-4">
                            <Form className="row gy-4 mt-4 mb-5">
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Choose Instructor</label>
                                        <div className="form-control-wrap">
                                            <RSelect
                                                options={instructorList}
                                                name="SelectsInstructor"
                                                placeholder="Choose Instructor"
                                                onChange={(e) => setSelectedInstructor(e.value)}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col size="12">
                                    <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button disabled={loading} onClick={(e) => handleChangeLessonInstructor(e)} color="primary" size="md" type="submit">
                                            {loading ? <Spinner size="sm" /> : "Yes"}
                                            </Button>
                                        </li>
                                        <li>
                                            <div
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModal({ changeInstructor: false });
                                            }}
                                            className="link link-light cursor_pointer"
                                            >
                                            Cancel
                                            </div>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default ChangeInstructorModal;
