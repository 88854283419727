import React, { useEffect, useState } from 'react';
import { Card, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from "reactstrap";
import { useDispatch ,useSelector} from "react-redux";
import ReactPaginate from "react-paginate";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Button, Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../components/Component";
import { GetInstructorDevicesDetailThunk } from '../../redux/InstructorDevicesSlice/GetInstructorDevicesDetailSlice';
import moment from 'moment';
import ViewInstructorDeviceDetailModal from './ViewInstructorDeviceDetailModal';

const InstructorDevices = () => {
    const dispatch = useDispatch();
    const { loading, GetInstructorDevicesData } = useSelector((state)=> state.instructorDevices.getInstructorDevicesDetail);

    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [selectedInstructor, setSelectectedInstructor] = useState({});
    const [modal, setModal] = useState({
        view: false
    })
    // ********************************** Search and Pagination start ***********************************//

    const formsPerPage = 10;
    const [allforms, setAllForms] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const pagesVisited = pageNumber * formsPerPage;
    const pageCount = Math.ceil(
        allforms.filter((listing) => {
            if (onSearchText === "") {
                return listing;
            } else if (
                listing?.fullName?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing?.creationDate?.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return listing;
            }
            return false;
        }).length / formsPerPage
    );

    const displayListing = allforms
        .filter((listing) => {
            if (onSearchText === "") {
                return listing;
            } else if (
                listing?.fullName?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing?.creationDate?.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return listing;
            }
            return false;
        })
        .slice(pagesVisited, pagesVisited + formsPerPage);

    useEffect(() => {
        setAllForms(GetInstructorDevicesData);
    }, [GetInstructorDevicesData]);

    useEffect(() => {
        setPageNumber(0);
    }, [onSearchText]);

    const paginate = ({ selected }) => setPageNumber(selected);

    // ********************************** Pagination End ***********************************//

    useEffect(() => {
        dispatch(GetInstructorDevicesDetailThunk());
    }, [dispatch]);

    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };

    const toggle = () => setonSearch(!onSearch);

    return (
        <React.Fragment>
            <Head title="User Created | Admin Portal"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Instructor's Device Details</BlockTitle>
                            {/* <BlockDes className="text-soft">
                                <p>You have total {droppedStudentsData?.length} students.</p>
                            </BlockDes> */}
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                        <Spinner />
                    </div>
                ) : (
                    <Block>
                        <Card className="card-bordered card-stretch">
                            <div className="card-inner-group">
                                <div className="card-inner">
                                    <div className="card-title-group">
                                        <div className="card-title">
                                            <h5 className="title">Instructor's Device Details</h5>
                                        </div>
                                        <div className="card-tools mr-n1">
                                            <ul className="btn-toolbar">
                                                <li>
                                                    <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                                                        <Icon name="search"></Icon>
                                                    </Button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                                            <div className="search-content">
                                                <Button
                                                    className="search-back btn-icon toggle-search"
                                                    onClick={() => {
                                                        setSearchText("");
                                                        toggle();
                                                    }}
                                                >
                                                    <Icon name="arrow-left"></Icon>
                                                </Button>
                                                <input
                                                    type="text"
                                                    className="form-control border-transparent form-focus-none"
                                                    placeholder="Search"
                                                    value={onSearchText}
                                                    onChange={(e) => onFilterChange(e)}
                                                />
                                                <Button className="search-submit btn-icon">
                                                    <Icon name="search"></Icon>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-inner p-0">
                                    <table className="table table-tranx">
                                        <thead>
                                            <tr className="tb-tnx-head">
                                            <th className="tb-tnx-id">
                                                    <span className="">Instructor Name</span>
                                                </th>
                                                <th className="tb-tnx-id">
                                                    <span className="">Device Name</span>
                                                </th>
                                                <th className="tb-tnx-info">
                                                    <span className="tb-tnx-desc d-none d-sm-inline-block">
                                                        <span>OS Version</span>
                                                    </span>
                                                    <span className="tb-tnx-date d-md-inline-block d-none">
                                                        <span className="d-none d-md-block">
                                                            <span>App Version</span>
                                                            <span>Last Updated</span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th className="tb-tnx-action">
                                                    <span>Action</span>
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayListing?.length > 0
                                                ? displayListing?.map((instructorDeviceDetail, index) => {
                                                    return (
                                                        <tr key={instructorDeviceDetail?._id} className="tb-tnx-item">
                                                            <td className="tb-tnx-id">
                                                                <div>
                                                                    <span>{instructorDeviceDetail?.fullName}</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-id">
                                                                <div>
                                                                    <span>{instructorDeviceDetail?.deviceName}</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-info">
                                                                <div className="tb-tnx-desc">
                                                                    <span className={`title tb-tnx-status`}>{instructorDeviceDetail?.deviceOSVersion}</span>
                                                                </div>
                                                                <div className="tb-tnx-date">
                                                                    <span className={`date tb-tnx-status`}>{instructorDeviceDetail?.appVersion}</span>
                                                                    <span className={`date tb-tnx-status`}>{instructorDeviceDetail?.lastUpdated? moment.utc(instructorDeviceDetail?.lastUpdated).format("DD/MM/YYYY"): "-"}</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-action">
                                                                <UncontrolledDropdown>
                                                                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                                                    <Icon name="more-h"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu right>
                                                                    <ul className="link-list-plain">
                                                                        <li>
                                                                            <DropdownItem onClick={(ev) => { ev.preventDefault(); setModal({ view: true }); setSelectectedInstructor(instructorDeviceDetail) }}>
                                                                                View Detail
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-inner">
                                    {displayListing?.length > 0 ? (
                                        <ReactPaginate
                                            breakLabel="..."
                                            breakClassName="pagination-btns"
                                            onPageChange={(number) => paginate(number)}
                                            pageRangeDisplayed={3}
                                            pageCount={pageCount}
                                            previousLabel="<<  Prev "
                                            renderOnZeroPageCount={null}
                                            nextLabel="Next >>"
                                            previousClassName="pagination-next"
                                            nextClassName="pagination-next"
                                            activeClassName="pagination-active"
                                            pageClassName="pagination mt-0"
                                            pageLinkClassName="pagination-btns"
                                            containerClassName="pagination justify-content-start"
                                        />
                                    ) : (
                                        <div className="text-center">
                                            <span className="text-silent">No data found</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Card>
                    </Block>
                )}
                {modal.view && <ViewInstructorDeviceDetailModal modal={modal} setModal={()=> setModal({view: false})} selectedInstructorData={selectedInstructor} />}
            </Content>
        </React.Fragment>
    )
}

export default InstructorDevices;
