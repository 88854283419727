import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSelector,useDispatch } from 'react-redux';
import { Button, Col, FormGroup, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { GetInstructorLessonsThunk } from '../../../redux/InstructorSlice/GetInstructorLessons';
import { AddInstructorOutOfOfficeThunk, ResetAddInstructorOutOfOfficeData } from '../../../redux/InstructorSlice/AddInstructorOutOfOfficeSlice';

const AddInstructorOutOfOfficeModal = ({ modal, setModal, instructorId }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage } = useSelector((state) => state.instructors.addInstructorOutOfOffice);

    const [scheduleTime, setScheduleTime] = useState({
        startTime: moment().startOf("hour").toDate(),
        endTime: moment().add(1, "hour").startOf("hour").toDate(),
        startDate: moment().toDate(),
        endDate: moment().toDate()
    });

    const handleAddOutOfOffice = () => {
        const addOnStartHours = moment(scheduleTime.startTime).toDate().getHours();
        const addOnStartMinutes = moment(scheduleTime.startTime).toDate().getMinutes();
        const addOnEndHours = moment(scheduleTime.endTime).toDate().getHours();
        const addOnEndMinutes = moment(scheduleTime.endTime).toDate().getMinutes();

        dispatch(AddInstructorOutOfOfficeThunk({ 
            startTime: moment(scheduleTime.startTime)
                .startOf("year").year(2022)
                .add("hours", addOnStartHours)
                .add("minutes", addOnStartMinutes)
                .utcOffset(0, true)
                .toISOString(), 
            endTime:  moment(scheduleTime.endTime)
                .startOf("year").year(2022)
                .add("hours", addOnEndHours)
                .add("minutes", addOnEndMinutes)
                .utcOffset(0, true)
                .toISOString(),
            startDate: moment(scheduleTime.startDate).utcOffset(0, false).format(), 
            endDate:  moment(scheduleTime.endDate).utcOffset(0, false).format(),
            instructorId: instructorId 
        }))
    }

    if (success) {
        toast.success("Out Of Office has been added successfully.");
        dispatch(ResetAddInstructorOutOfOfficeData());
        dispatch(GetInstructorLessonsThunk({ instructorId: instructorId }));
        setModal();
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetAddInstructorOutOfOfficeData());
        dispatch(GetInstructorLessonsThunk({ instructorId: instructorId }));
        setModal();
    }

    const { handleSubmit } = useForm();
    return (
        <React.Fragment>
            <Modal
                isOpen={modal.addOutOfOffice}
                toggle={() => setModal(false)}
                className="modal-md"
            >
                <ModalHeader tag="h4" toggle={() => setModal(false)}>
                Add Out Of Office
                </ModalHeader>
                <ModalBody>
                    <form className="form-validate is-alter" onSubmit={handleSubmit(handleAddOutOfOffice)}>
                    <Row className="gx-4 gy-3">
                        <Col sm="6">
                        <FormGroup>
                            <label className="form-label">Start Date</label>
                            <Row className="gx-2" style={{ zIndex: "2" }}>
                            <div className="w-100" style={{ zIndex: "2" }}>
                                <div className="form-control-wrap has-timepicker" style={{ zIndex: "2" }}>
                                    <DatePicker
                                        className="form-control date-picker"
                                        style={{ zIndex: "2" }}
                                        selected={scheduleTime.startDate}
                                        onChange={(date) => setScheduleTime({ ...scheduleTime, startDate: date })}
                                    />
                                </div>
                            </div>
                            </Row>
                        </FormGroup>
                        </Col>
                        <Col sm="6">
                        <FormGroup>
                            <label className="form-label">End Date</label>
                            <Row className="gx-2" style={{ zIndex: "2" }}>
                            <div className="w-100" style={{ zIndex: "2" }}>
                                <div className="form-control-wrap has-timepicker" style={{ zIndex: "2" }}>
                                    <DatePicker
                                        className="form-control date-picker"
                                        style={{ zIndex: "2" }}
                                        selected={scheduleTime.endDate}
                                        onChange={(date) => setScheduleTime({ ...scheduleTime, endDate: date })}
                                    />
                                </div>
                            </div>
                            </Row>
                        </FormGroup>
                        </Col>

                        <Col sm="6">
                            <FormGroup>
                                <label className="form-label">Start Time</label>
                                <Row className="gx-2" style={{ zIndex: "2" }}>
                                <div className="w-100" style={{ zIndex: "2" }}>
                                    <div className="form-control-wrap has-timepicker" style={{ zIndex: "2" }}>
                                    <DatePicker
                                        style={{ zIndex: "2" }}
                                        selected={scheduleTime.startTime}
                                        onChange={(date) => setScheduleTime({ ...scheduleTime, startTime: date })}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        className="form-control date-picker"
                                    />
                                    </div>
                                </div>
                                </Row>
                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup>
                                <label className="form-label">End Time</label>
                                <Row className="gx-2">
                                    <div className="w-100">
                                        <div className="form-control-wrap has-timepicker" style={{ opacity: 3 }}>
                                        <DatePicker
                                            selected={scheduleTime.endTime}
                                            onChange={(date) => setScheduleTime({ ...scheduleTime, endTime: date })}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={30}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            className="form-control date-picker"
                                        />
                                        </div>
                                    </div>
                                </Row>
                            </FormGroup>        
                        </Col>
                        <Col size="12">
                            <ul className="d-flex justify-content-between gx-4 mt-1">
                                <li>
                                    <Button disabled={loading} type="submit" color="primary">
                                        {loading ? <Spinner /> : "Add Out Of Office"}
                                    </Button>
                                </li>
                                <li>
                                    <Button
                                        color="#ffffff"
                                        className="btn-dim"
                                        onClick={() => setModal(false)}
                                    >
                                        Cancel
                                    </Button>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    </form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default AddInstructorOutOfOfficeModal;