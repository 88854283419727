import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const LoginThunk = createAsyncThunk("Login/LoginThunk", async ({ email, password }, { rejectWithValue }) => {
  try {
    const response = await axios.post(ApiEndpoints.Login, {
      email,
      password,
    });

    return response.data.data;
  } catch (error) {
    console.log("error", error);
    return rejectWithValue(error.response.data);
  }
});

const LoginSlice = createSlice({
  name: "Login",
  initialState: {
    authToken: "",
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
    adminData: {},
  },
  reducers: {
    ResetLoginData: (state, action) => {
      state.authToken = "";
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [LoginThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [LoginThunk.fulfilled]: (state, action) => {
      state.adminData = action.payload;
      state.authToken = action.payload.token;
      state.success = true;
      state.loading = false;
    },
    [LoginThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default LoginSlice.reducer;

export const { ResetAllStudentData } = LoginSlice.actions;

export { LoginThunk };
