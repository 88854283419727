import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetStudentNotificationThunk = createAsyncThunk("GetStudentNotification/GetStudentNotificationThunk", async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get(
            `${ApiEndpoints.Configuration.GetStudentNotification}`,
            {
                headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            }
        );
        return response.data;
    } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
    }
}
);

const GetStudentNotificationSlice = createSlice({
    name: "GetStudentNotification",
    initialState: {
        getStudentNotificationData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetGetStudentNotificationData: (state, action) => {
            state.getStudentNotificationData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },

    extraReducers: {
        [GetStudentNotificationThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [GetStudentNotificationThunk.fulfilled]: (state, action) => {
            state.getStudentNotificationData = action.payload.data;
            state.success = true;
            state.loading = false;
        },
        [GetStudentNotificationThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
            state.getStudentNotificationData = {};
        },
    },
});

export default GetStudentNotificationSlice.reducer;

export const { ResetGetStudentNotificationData } = GetStudentNotificationSlice.actions;

export { GetStudentNotificationThunk };
