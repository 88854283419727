import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiEndpoints } from "../../utils/ApiEndpoints";
import axios from "axios";

const GetDashboradStatsThunk = createAsyncThunk(
  "GetDashboardStats/GetDashboradStatsThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(ApiEndpoints.GetDashboardStats, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
      return response;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const GetDashboardStatsSlice = createSlice({
  name: "GetDashboardStats",
  initialState: {
    allStats: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetDashboardStatsData: (state, action) => {
      state.allStats = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [GetDashboradStatsThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GetDashboradStatsThunk.fulfilled]: (state, action) => {
      state.allStats = action.payload.data;
      state.success = true;
      state.loading = false;
    },
    [GetDashboradStatsThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.error = true;
      state.loading = false;
    },
  },
});

export default GetDashboardStatsSlice.reducer;

export const { ResetDashboardStatsData } = GetDashboardStatsSlice.actions;

export { GetDashboradStatsThunk };
