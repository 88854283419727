import React from 'react'
import { useDispatch } from 'react-redux';
import { ChangeInvoiceStatusThunk, ResetChangeInvoiceStatusData } from '../../../redux/Invoices/ChangeInvoiceStatusSlice';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { GetAllInstructorInvoicesThunk } from '../../../redux/InstructorSlice/GetAllInstructorInvoiceSlice';
import { Button, Col, Form, Modal, ModalBody, Spinner } from 'reactstrap';
import { Icon } from '../../../components/Component';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const ChangeInstructorInvoiceStatusModal = ({modal, setModal, selectedInvoiceId}) => {
    const dispatch = useDispatch();
    const { instructorId } = useParams();
    const { 
        loading: changeStatusLoading,
        success: changeStatusSuccess,
        error: changeStatusError,
        errorMessage: changeStatusErrorMessage 
    } = useSelector(((state)=> state.invoices.changeInvoiceStatus))
    
    const handleChangeStatus = (e) => { 
        e.preventDefault();
        dispatch(ChangeInvoiceStatusThunk({ invoiceId: selectedInvoiceId }));
    }

    if(changeStatusSuccess){
        toast.success("Status has been changed successfully.");
        dispatch(ResetChangeInvoiceStatusData());
        dispatch(GetAllInstructorInvoicesThunk({instructorId: instructorId }));
        setModal({changeStatus: false});
    }

    if(changeStatusError){
        toast.error(changeStatusErrorMessage);
        dispatch(ResetChangeInvoiceStatusData());
        setModal({changeStatus: false});
    }
    
    return (
        <React.Fragment>
                <Modal isOpen={modal.changeStatus} toggle={() => setModal({ changeStatus: false })} className="modal-dialog-centered" size="md">
                    <ModalBody>
                        <div onClick={(ev) => { ev.preventDefault(); setModal({ changeStatus: false }); }} className="close cursor_pointer">
                            <Icon name="cross-sm"></Icon>
                        </div>
                        <div className="p-2">
                            <h4 className="title">Change Invoice Status</h4>
                            <div className="mt-4">
                                <Formik>
                                    <Form className="row gy-4 mt-4 mb-5">
                                        <Col md="12">
                                            <div>
                                                <span>Are you sure you want to change invoice status?</span>
                                            </div>
                                        </Col>
                                        <Col size="12">
                                            <ul className="d-flex justify-content-start flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <Button disabled={changeStatusLoading} onClick={handleChangeStatus} color="primary" size="md" type="submit">
                                                        <span>{changeStatusLoading ? <Spinner size="sm" /> : "Mark as paid"} </span>
                                                    </Button>
                                                </li>
                                                <li className="d-flex align-items-center cursor_pointer">
                                                    <div onClick={(ev) => { ev.preventDefault(); setModal({ changeStatus: false }); }} className="link link-light">
                                                        Cancel
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </ModalBody>
            </Modal>
        </React.Fragment>
    )
};

export default ChangeInstructorInvoiceStatusModal;