import React, { useEffect, useState } from "react";
import { FormGroup, Modal, ModalBody, Spinner, Col, Button } from "reactstrap";
import { Icon, RSelect } from "../../components/Component";
import { useDispatch } from "react-redux";
import { GetInstructorListThunk } from "../../redux/InstructorSlice/GetInstructorListSlice";
import { useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import { AddStudentInitialValues, AddStudentNotesInitialValues } from "../../utils/FormValues";
import { AddStudentNotesValidationSchema, AddStudentValidationSchema } from "../../utils/FormValidationSchema";
import { AddStudentThunk, ResetAllStudentLoader } from "../../redux/StudentsSlice/GetAllStudentSlice";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { GetStudentNotesThunk } from "../../redux/StudentsSlice/GetStudentNotesSlice";
import { AddStudentNotesThunk, ResetAddStudentNotesData } from "../../redux/StudentsSlice/AddStudentNotesSlice";

const AddStudentNotesModal = ({ setModal, modal }) => {
    const dispatch = useDispatch();
    const {studentId } = useParams();
    const {
        loading,
        success,
        error,
        errorMessage,
    } = useSelector((state) => state.Students.addStudentNotes);

    const [formData, setFormData] = useState({
        type: "",
        instructorId: "",
    });

    useEffect(() => {
        dispatch(GetInstructorListThunk());
        dispatch(ResetAllStudentLoader());
    }, [modal, dispatch]);

    // function to close the form modal
    const onFormCancel = () => {
        setModal({ add: false });
        // resetForm();
    };

    // submit function to add a new item
    const onFormSubmit = ({ fullName }) => {
        dispatch(
        AddStudentNotesThunk({
            notes: fullName,
            studentId: studentId
        })
        );
    };

    if (success) {
        toast.success("Student Notes has been added successfully.");
        dispatch(ResetAddStudentNotesData());
        dispatch(GetStudentNotesThunk({studentId: studentId}))
        setModal({ add: false });
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetAddStudentNotesData());
        setModal({ add: false });
    }

    return (
        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
        <ModalBody>
            <div
            onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
            }}
            className="close cursor_pointer"
            >
            <Icon name="cross-sm"></Icon>
            </div>
            <div className="p-2">
                <h5 className="title">Add Student Note</h5>
                <div className="mt-4">
                <Formik
                    initialValues={AddStudentNotesInitialValues}
                    validationSchema={AddStudentNotesValidationSchema}
                    enableReinitialize={true}
                    onSubmit={onFormSubmit}
                >
                    {({ errors, touched }) => (
                    <Form className="row gy-4 mt-4">
                        <Col md="12">
                            <FormGroup>
                                <label className="form-label">Notes</label>
                                <Field
                                    className="form-control"
                                    as="textarea"
                                    name="fullName"
                                    // defaultValue={formData.name}
                                    placeholder="Enter Notes"
                                />
                                {errors.fullName && touched.fullName && <span className="invalid">{errors.fullName}</span>}
                            </FormGroup>
                        </Col>
                        <Col size="12">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                <Button disabled={errors ? false : true} color="primary" size="md" type="submit">
                                    {loading ? <Spinner /> : "Add Student Note"}
                                </Button>
                                </li>
                                <li>
                                    <div
                                        onClick={(ev) => {
                                        ev.preventDefault();
                                        onFormCancel();
                                        }}
                                        className="link link-light cursor_pointer"
                                    >
                                        Cancel
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Form>
                    )}
                </Formik>
                </div>
            </div>
        </ModalBody>
        </Modal>
    );
};

export default AddStudentNotesModal;
