import { combineReducers } from "@reduxjs/toolkit";
import GetAllStudentSlice from "./GetAllStudentSlice";
import GetStudentPersonalDetail from "./StudentPersonalDetail";
import GetStudentLessonsSlice from "./GetStudentLessonsSlice.js";
import GetStudentRoadTestSlice from "./GetStudentRoadTestSlice";
import GetStudentReviewsSlice from "./GetStudentReviewsSlice";
import GetStudentInstructorsSlice from "./GetStudentInstructorsSlice";
import AddStudentSlice from "./AddStudentSlice";
import DeleteStudentSlice from "./DeleteStudentSlice";
import AddStudentRoadTestLessonSlice from "./AddStudentRoadTestLessonSlice";
import EditStudentProfileSlice from "./EditStudentProfileSlice";
import ChangeStudentPasswordSlice from "./ChangeStudentPasswordSlice";
import { InCarSheetReducers } from "./InCarSheetSlice";
import GetStudentNotesSlice from "./GetStudentNotesSlice";
import AddStudentNotesSlice from "./AddStudentNotesSlice";
import GetPackagesSlice from "./GetPackagesSlice.js";
import GetStudentRefundsSlice from "./GetStudentRefundsSlice.js";
import UpdateStudentRefundStatusSlice from "./UpdateStudentRefundStatusSlice.js";
import AssignStudentInstructorSlice from "./AssignStudentInstructorSlice.js";

export const StudentsReducers = combineReducers({
  addStudent: AddStudentSlice,
  getAllStudentSlice: GetAllStudentSlice,
  getStudentPersonalDetail: GetStudentPersonalDetail,
  getStudentLessons: GetStudentLessonsSlice,
  getStudentRoadTest: GetStudentRoadTestSlice,
  getStudentReviews: GetStudentReviewsSlice,
  getStudentInstructors: GetStudentInstructorsSlice,
  assignStudentInstructor: AssignStudentInstructorSlice,
  deleteStudent: DeleteStudentSlice,
  addStudentRoadTest: AddStudentRoadTestLessonSlice,
  editStudentProfile: EditStudentProfileSlice,
  changeStudentPassword: ChangeStudentPasswordSlice,
  getStudentNotes: GetStudentNotesSlice,
  addStudentNotes: AddStudentNotesSlice,
  InCarSheet: InCarSheetReducers,
  getPackages: GetPackagesSlice,
  getStudentRefunds: GetStudentRefundsSlice,
  updateStudentRefund: UpdateStudentRefundStatusSlice,
});
