import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const AddInstructorReviewsThunk = createAsyncThunk(
    "AddInstructorReviews/AddInstructorReviewsThunk",
    async ({ instructorId, review, rating, studentName }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiEndpoints.InstructorsEndPoint.AddInstructorReviews}?instructorId=${instructorId}`,
                { review, rating, studentName },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                }
            );
            return response.data?.reviews;
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error.response.data);
        }
    }
);

const AddInstructorReviewsSlice = createSlice({
    name: "AddInstructorReviews",
    initialState: {
        addInstructorReviewsData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAddInstructorReviewsData: (state, action) => {
            state.addInstructorReviewsData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [AddInstructorReviewsThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [AddInstructorReviewsThunk.fulfilled]: (state, action) => {
            state.addInstructorReviewsData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [AddInstructorReviewsThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.addInstructorReviewsData = {};

        },
    },
});

export default AddInstructorReviewsSlice.reducer;

export const { ResetAddInstructorReviewsData } = AddInstructorReviewsSlice.actions;

export { AddInstructorReviewsThunk };
