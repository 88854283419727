import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const ScheduleRoadTestThunk = createAsyncThunk(
  "ScheduleRoadTest/ScheduleRoadTestThunk",
  async ({ lessonId, pickupLocation, latitude, longitude, date, startTime, endTime, instructorId, roadTestType, roadTestTime, roadTestCenterAddress }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${ApiEndpoints.LesssonsEndPoint.ScheduleRoadTest}?lessonId=${lessonId}&instructorId=${instructorId}`,
        { pickupLocation, latitude, longitude, date, startTime, endTime, roadTestType, roadTestTime, roadTestCenterAddress },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const ScheduleRoadTestSlice = createSlice({
  name: "ScheduleRoadTest",
  initialState: {
    ScheduleRoadTestData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetScheduleRoadTestData: (state, action) => {
      state.ScheduleRoadTestData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [ScheduleRoadTestThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [ScheduleRoadTestThunk.fulfilled]: (state, action) => {
      state.ScheduleRoadTestData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [ScheduleRoadTestThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default ScheduleRoadTestSlice.reducer;

export const { ResetScheduleRoadTestData } = ScheduleRoadTestSlice.actions;

export { ScheduleRoadTestThunk };
