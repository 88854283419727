import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Head from "../../../layout/head/Head";
import CurrentStudents from "./CurrentStudents";
import GraduatedStudents from "./GraduatedStudents";
import Content from "../../../layout/content/Content";
import { GetInstructorStudentsThunk } from "../../../redux/InstructorSlice/GetInstructorStudents";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";

const InstructorStudents = () => {
    const dispatch = useDispatch();
    const { instructorId } = useParams();

    const { loading, instructorStudentData } = useSelector(
        (state) => state.instructors.getInstructorStudent
    );

    useEffect(() => {
        dispatch(GetInstructorStudentsThunk({ instructorId }));
    }, []);

    return (
        <React.Fragment>
            <Head title="Instructor's Student"></Head>
            <Head title="Instructor's Student"></Head>
            <Content>
                {loading ? 
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                        <Spinner />
                    </div>
                : (
                    <>
                        <CurrentStudents currentStudentsData={instructorStudentData.active} />
                        <GraduatedStudents graduatedStudentsData={instructorStudentData.graduated} />
                    </>
                )
            }
            </Content>
        </React.Fragment>
    );
};

export default InstructorStudents;
