import React, { useState, useEffect } from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Card,
  FormGroup,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
  Spinner,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import axios from "axios";

import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  PaginationComponent,
  RSelect,
} from "../../components/Component";
import { DeleteInstructorThunk, ResetDeleteInstructorData } from "../../redux/InstructorSlice/DeleteInstructorSlice";
import { AddInstructorThunk, ResetAddInstructorData } from "../../redux/InstructorSlice/AddInstructorSlice";
import { GetAllInstructorsThunk } from "../../redux/InstructorSlice/GetAllInstructorSlice";
import { GetAllPickupPointsThunk } from "../../redux/PickupPoints/GetAllPickupPoints";
import { ApiEndpoints } from "../../utils/ApiEndpoints";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

const InstructorsList = () => {
  const dispatch = useDispatch();

  const { loading, allInstructorsData } = useSelector(
    (state) => state.instructors.getAllInstructors
  );

  const {
    pickupPointsData,
  } = useSelector((state) => state.pickupPoints.getAllPickupPoints);

  const {
    loading: AddInstructorLoading,
    success: AddInstructorSuccess,
    error: AddInstructorError,
    errorMessage: AddInstructorErrorMessage,
  } = useSelector((state) => state.instructors.addInstructor);

  const {
    loading: DeleteInstructorLoading,
    success: DeleteInstructorSuccess,
    error: DeleteInstructorError,
    errorMessage: DeleteInstructorErrorMessage,
  } = useSelector((state) => state.instructors.deleteInstructor);


  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [pickupPointsList, setPickupPointsList] = useState([]);
  const [modal, setModal] = useState({
    add: false,
    delete: false
  });

  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    password: "",
    instructorImage: "",
    locationId: "",
  });
  const [LogoImageLoading, setLogoImageLoading] = useState(false);
  const [selectedInstructorId, setSelecredInstructorId] = useState('');
  const instructorsPerPage = 10;
  const [allInstructorsList, setAllInstructorsList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  // ********************************** Search and Pagination start ***********************************//
  const pagesVisited = pageNumber * instructorsPerPage - instructorsPerPage;

  const pageCount = Math.ceil(
    allInstructorsList.filter((instructorData) => {
      if (onSearchText === "") {
        return instructorData;
      } else if (
        instructorData.email.toLowerCase().includes(onSearchText.toLowerCase()) ||
        instructorData.fullName.toLowerCase().includes(onSearchText.toLowerCase()) ||
        instructorData.phoneNumber.toLowerCase().includes(onSearchText.toLowerCase()) ||
        instructorData.status.toLowerCase().includes(onSearchText.toLowerCase())
      ) {
        return instructorData;
      }
      return false;
    }).length / instructorsPerPage
  );

  const displayInstructorsList = allInstructorsList
    .filter((instructorData) => {
      if (onSearchText === "") {
        return instructorData;
      } else if (
        instructorData.email.toLowerCase().includes(onSearchText.toLowerCase()) ||
        instructorData.fullName.toLowerCase().includes(onSearchText.toLowerCase()) ||
        instructorData.phoneNumber.toLowerCase().includes(onSearchText.toLowerCase()) ||
        instructorData.status.toLowerCase().includes(onSearchText.toLowerCase())
      ) {
        return instructorData;
      }
      return false;
    })
    .slice(pagesVisited, pagesVisited + instructorsPerPage);

  // ********************************** Pagination End ***********************************//

  useEffect(() => {
    dispatch(GetAllInstructorsThunk());
    dispatch(GetAllPickupPointsThunk());
  }, [dispatch]);

  useEffect(() => {
    const sortedInstructor = allInstructorsData?.slice().sort((a, b) => {
      const nameA = a?.fullName?.toLowerCase();
      const nameB = b?.fullName?.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    })
    setAllInstructorsList(sortedInstructor);
  }, [allInstructorsData]);

  useEffect(() => {
    if (pickupPointsData?.pickupLocations?.length !== 0) {
      const list = pickupPointsData?.pickupLocations?.map((pickupPoint, index) => {
        return { label: `(${pickupPoint.name}) - ${pickupPoint?.address?.location}`, value: pickupPoint._id };
      });
      setPickupPointsList(list);
    }
  }, [pickupPointsData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // submit function to add a New Instructor  
  const handleAddInstructor = () => {
    dispatch(
      AddInstructorThunk({
        instructorImage: formData.instructorImage,
        fullName: formData.fullName,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        password: formData.password,
        locationId: formData.locationId,
      })
    );
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Change Page
  const paginate = (selected) => {
    setPageNumber(selected);
  };

  const { errors, register, handleSubmit } = useForm();

  const handleLogoImageUploader = async (e) => {
    e.preventDefault();
    const formDt = new FormData();
    setLogoImageLoading(true);
    formDt.append("image", e.target.files[0]);
    try {
      const response = await axios.post(`${ApiEndpoints.UploadImages}/instructors`, formDt, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setFormData({ ...formData, instructorImage: response.data.imageName });
      setLogoImageLoading(false);
    } catch (error) {
      setLogoImageLoading(false);
    }
  };

  const handleDeleteInstructor = (e) => {
    e.preventDefault();
    dispatch(DeleteInstructorThunk({ instructorId: selectedInstructorId }));
  };

  if (AddInstructorSuccess) {
    toast.success("Instructor has been added successfully.");
    dispatch(ResetAddInstructorData());
    dispatch(GetAllInstructorsThunk());
    setModal({ add: false });
  }

  if (AddInstructorError) {
    toast.error(AddInstructorErrorMessage);
    dispatch(ResetAddInstructorData());
    dispatch(GetAllInstructorsThunk());
    setModal({ add: false });
  }

  if (DeleteInstructorSuccess) {
    toast.success("Instructor has been Deleted successfully.");
    dispatch(ResetDeleteInstructorData());
    dispatch(GetAllInstructorsThunk());
    setModal({ delete: false });
  }

  if (DeleteInstructorError) {
    toast.error(DeleteInstructorErrorMessage);
    dispatch(ResetDeleteInstructorData());
    dispatch(GetAllInstructorsThunk());
    setModal({ delete: false });
  }

  return (
    <React.Fragment>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "90vh" }}>
          <Spinner />
        </div>
      ) : (
        <>
          <Head title="Instructors"></Head>
          <Content>
            <>
              <BlockHead size="sm">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle page>Instructors</BlockTitle>
                    <BlockDes className="text-soft">
                      <p>You have total {allInstructorsList?.length} instructors.</p>
                    </BlockDes>
                  </BlockHeadContent>
                  <BlockHeadContent>
                    <ul className="nk-block-tools-opt">
                      <li>
                        <Button color="primary" onClick={() => setModal({ add: true })}>
                          <Icon name="plus" />
                          <span>Add Instructor</span>
                        </Button>
                      </li>
                    </ul>
                  </BlockHeadContent>{" "}
                </BlockBetween>
              </BlockHead>

              <Block>
                <Card className="card-bordered card-stretch">
                  <div className="card-inner-group">
                    <div className="card-inner">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">All Instructors</h5>
                        </div>
                        <div className="card-tools mr-n1">
                          <ul className="btn-toolbar">
                            <li>
                              <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                                <Icon name="search"></Icon>
                              </Button>
                            </li>
                          </ul>
                        </div>
                        <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                          <div className="search-content">
                            <Button
                              className="search-back btn-icon toggle-search"
                              onClick={() => {
                                setSearchText("");
                                toggle();
                              }}
                            >
                              <Icon name="arrow-left"></Icon>
                            </Button>
                            <input
                              type="text"
                              className="form-control border-transparent form-focus-none"
                              placeholder="Search by Instructor name"
                              value={onSearchText}
                              onChange={(e) => onFilterChange(e)}
                            />
                            <Button className="search-submit btn-icon">
                              <Icon name="search"></Icon>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner p-0">
                      <table className="table table-tranx">
                        <thead>
                          <tr className="tb-tnx-head">
                            <th className="tb-tnx-id">
                              <span className="">#</span>
                            </th>
                            <th className="tb-tnx-info">
                              <span className="tb-tnx-desc d-none d-sm-inline-block">
                                <span>Name</span>
                              </span>
                              <span className="tb-tnx-date d-md-inline-block d-none">
                                <span className="d-none d-md-block">
                                  <span>Email</span>
                                  <span>Phone Number</span>
                                </span>
                              </span>
                            </th>
                            <th className="tb-tnx-amount is-alt">
                              <span className="tb-tnx-status d-none d-md-inline-block">Status</span>
                            </th>
                            <th className="tb-tnx-action">
                              <span>Action</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {displayInstructorsList.length > 0 ? (
                            displayInstructorsList.map((instructorData, index) => {
                              return (
                                <tr key={index} className="tb-tnx-item">
                                  <td className="tb-tnx-id">
                                    <div onClick={(ev) => { ev.preventDefault(); }}>
                                      <span>{(pageNumber - 1) * instructorsPerPage + index + 1}</span>
                                    </div>
                                  </td>
                                  <td className="tb-tnx-info">
                                    <div className="tb-tnx-desc">
                                      <span className="title">{instructorData.fullName}</span>
                                    </div>
                                    <div className="tb-tnx-date">
                                      <span className="date">{instructorData.email}</span>
                                      <span className="date">{instructorData.phoneNumber}</span>
                                    </div>
                                  </td>

                                  <td className="tb-tnx-amount is-alt">
                                    <div className="tb-tnx-status">
                                      <span
                                        className={`badge badge-dot badge-${instructorData.status === "Approved"
                                          ? "success"
                                          : instructorData.status === "Pending"
                                            ? "warning"
                                            : "danger"
                                          }`}
                                      >
                                        {instructorData.status}
                                      </span>
                                    </div>
                                  </td>
                                  <td className="tb-tnx-action">
                                    <UncontrolledDropdown>
                                      <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                        <Icon name="more-h"></Icon>
                                      </DropdownToggle>
                                      <DropdownMenu right>
                                        <ul className="link-list-plain">
                                          <li>
                                            <Link to={`/instructors/${instructorData?._id}`}>View Details</Link>
                                          </li>
                                          <li>
                                            <DropdownItem style={{color: "red"}} onClick={(ev) => { ev.preventDefault(); setModal({ delete: true }); setSelecredInstructorId(instructorData?._id) }}>
                                              Delete Instructor
                                            </DropdownItem>
                                          </li>
                                        </ul>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <span>No Data Found</span>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="card-inner">
                      {allInstructorsList.length > 0 ? (
                        <PaginationComponent noDown itemPerPage={instructorsPerPage} totalItems={pageCount} paginate={paginate} currentPage={pageNumber} />
                      ) : (
                        <div className="text-center">
                          <span className="text-silent">No data found</span>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              </Block>
            </>

            <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
              <ModalBody>
                <div onClick={(ev) => { ev.preventDefault(); setModal({ add: false }); }} className="close cursor_pointer">
                  <Icon name="cross-sm"></Icon>
                </div>
                <div className="p-2">
                  <h5 className="title">Add Instructor</h5>
                  <div className="mt-4">
                    <Form className="row gy-4 mt-4" onSubmit={handleSubmit(handleAddInstructor)}>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">Full Name</label>
                          <input
                            className="form-control"
                            ref={register({ required: "This field is required" })}
                            type="text"
                            name="fullName"
                            onChange={(e) => onInputChange(e)}
                            defaultValue={formData.fullName}
                            placeholder="Enter Name"
                          />
                          {errors.fullName && <span className="invalid">{errors.fullName.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">Phone Number</label>
                          <input
                            className="form-control"
                            ref={register({ required: "This field is required" })}
                            type="number"
                            name="phoneNumber"
                            onChange={(e) => onInputChange(e)}
                            defaultValue={formData.phoneNumber}
                          />
                          {errors.phoneNumber && <span className="invalid">{errors.phoneNumber.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">Email</label>
                          <input
                            className="form-control"
                            ref={register({ required: "This field is required" })}
                            type="text"
                            name="email"
                            onChange={(e) => onInputChange(e)}
                            defaultValue={formData.email}
                            placeholder="Enter Email"
                          />
                          {errors.email && <span className="invalid">{errors.email.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">Password</label>
                          <input
                            className="form-control"
                            ref={register({ required: "This field is required" })}
                            type="password"
                            name="password"
                            onChange={(e) => onInputChange(e)}
                            defaultValue={formData.password}
                            placeholder="Enter Name"
                          />
                          {errors.password && <span className="invalid">{errors.password.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-label">Profile Image</label>
                          <input
                            className="form-control"
                            style={{ cursor: "pointer" }}
                            // ref={register({ required: "This field is required" })}
                            type="file"
                            name="logo"
                            onChange={(e) => handleLogoImageUploader(e)}
                          />
                          {LogoImageLoading && <Spinner />}
                          {errors.logo && <span className="invalid">{errors.logo.message}</span>}
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <FormGroup>
                          <label className="form-label">Assign Pickup Location</label>
                          <div className="form-control-wrap">
                            <RSelect
                              options={pickupPointsList}
                              onChange={(e) => setFormData({ ...formData, locationId: e.value })}
                            />
                          </div>
                        </FormGroup>
                      </Col>

                      <Col size="12">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                          <li>
                            <Button color="primary" size="md" type="submit">
                              {AddInstructorLoading ? <Spinner size="sm" /> : "Add Instructor"}
                            </Button>
                          </li>
                          <li>
                            <a
                              href="#cancel"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setModal({ add: false });
                              }}
                              className="link link-light"
                            >
                              Cancel
                            </a>
                          </li>
                        </ul>
                      </Col>
                    </Form>
                  </div>
                </div>
              </ModalBody>
            </Modal>

            <Modal isOpen={modal.delete} toggle={() => setModal({ delete: false })} className="modal-dialog-centered" size="md">
              <ModalBody>
                <div onClick={(ev) => { ev.preventDefault(); setModal({ delete: false }); }} className="close" style={{ cursor: "pointer" }}>
                  <Icon name="cross-sm"></Icon>
                </div>
                <div className="p-2">
                  <h5 className="title">Delete Instructor</h5>
                  <div className="mt-4">
                    <Form className="row gy-4" >
                      <Col size="12">
                        <h6>Are you sure you want to delete Instructor?</h6>
                      </Col>
                      <Col size="12">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                          <li>
                            <Button onClick={handleDeleteInstructor} type="submit" color="primary" size="md" >
                              <span>{DeleteInstructorLoading ? <Spinner size="md" /> : "Delete"} </span>
                            </Button>
                          </li>
                          <li>
                            <Button onClick={(ev) => { ev.preventDefault(); setModal({ delete: false }); }} className="link link-light">
                              Cancel
                            </Button>
                          </li>
                        </ul>
                      </Col>
                    </Form>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </Content>
        </>
      )}
    </React.Fragment>
  );
};

export default InstructorsList;
